import PublicClient from 'api/public'
import {
  GET_SERVICES_BY_PRODUCT_QUERY,
  GET_DELIVERY_METHODS_BY_PRODUCT_QUERY
} from 'graphql/queries'
import { ServiceRecord, ServicesDataViewData } from 'graphql/types'

export const getServicesByProduct = async (
  productSku: string
): Promise<ServiceRecord[]> => {
  const { data, error } = await PublicClient.query<ServicesDataViewData>({
    query: GET_SERVICES_BY_PRODUCT_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_SERVICES_BY_PRODUCT_ID,
      parameters: {
        productSku: productSku
      }
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}

export const getDeliveryMethodsByProduct = async (
  productSku: string
): Promise<any[]> => {
  const { data, error } = await PublicClient.query<any>({
    query: GET_DELIVERY_METHODS_BY_PRODUCT_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_DELIVERY_METHODS_BY_PRODUCT_ID,
      parameters: {
        productSku: productSku
      }
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}
