import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  ListItem,
  Heading,
  Button,
  Dialog,
  TextField,
  ErrorPage,
  Spinner
} from 'uniqore-components'
import { CircularProgress } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import styled from 'styled-components/macro'
import { INGEST_CLIENT_PRODUCTS_FORM } from 'graphql/queries'
import PublicClient from 'api/public'
import { useMutation } from '@apollo/client'
import { SetClientProductsUpdated } from 'store/actions/product'
import { SessionStatus } from 'store/actions/common'
import TehoAppBar from 'components/common/TehoAppBar'

const useStyles = makeStyles(theme => ({
  line: {
    margin: '16px',
    border: '0',
    height: '0',
    opacity: '20%',
    borderTop: `1px solid ${theme.palette.text.primary}`
  }
}))

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProductCard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { SelectedProduct } = useSelector((state: AppState) => state.product)
  const { sessionStatus } = useSelector((state: AppState) => state.common)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState<boolean>(true)
  const [
    openConfirmationDialog,
    setOpenConfirmationDialog
  ] = React.useState<boolean>(false)
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [serialNumber, setSerialNumber] = React.useState<string>(
    SelectedProduct.serialNumber
  )

  const handleSubmit = () => {
    setLoading(true)
    updateClientProductSerialNumber()
  }

  const [updateClientProductSerialNumber] = useMutation(
    INGEST_CLIENT_PRODUCTS_FORM,
    {
      client: PublicClient,
      variables: {
        id: process.env.REACT_APP_FORM_UPDATE_CLIENT_PRODUCT_SERIAL_NUMBER_ID,
        form: {
          id: SelectedProduct ? SelectedProduct.products.id : '',
          productSerialNumber: serialNumber
        }
      },
      onCompleted(mutationResult) {
        dispatch(SetClientProductsUpdated(true))
        setLoading(false)
        setOpenDialog(false)
      },
      onError(mutationError) {
        if (!localStorage.getItem('token') && sessionStatus) {
          dispatch(SessionStatus(false))
        } else {
          const link = {
            url: window.location.pathname,
            text: 'Yritä uudestaan'
          }
          return (
            <div>
              <ErrorPage
                backgroundColor="#fff"
                color="#000"
                link={link}
                text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
              />
            </div>
          )
        }
      }
    }
  )

  const handleDelete = () => {
    setLoading(true)
    setOpenConfirmationDialog(false)
    deleteProduct()
  }

  const [deleteProduct] = useMutation(INGEST_CLIENT_PRODUCTS_FORM, {
    client: PublicClient,
    variables: {
      id: process.env.REACT_APP_FORM_DELETE_CLIENT_PRODUCT_ID,
      form: {
        id: SelectedProduct ? SelectedProduct.products.id : '',
        Deleted: true
      }
    },
    onCompleted(mutationResult) {
      setLoading(false)
      history.push('/customer/products')
    },
    onError(mutationError) {
      if (!localStorage.getItem('token') && sessionStatus) {
        dispatch(SessionStatus(false))
      } else {
        const link = {
          url: window.location.pathname,
          text: 'Yritä uudestaan'
        }
        return (
          <div>
            <ErrorPage
              backgroundColor="#fff"
              color="#000"
              link={link}
              text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
            />
          </div>
        )
      }
    }
  })

  let attachments: Object[] = []
  if (
    SelectedProduct &&
    SelectedProduct.products.fields['Product attachments']
  ) {
    SelectedProduct.products.fields['Product attachments'].map((product: any) =>
      attachments.push(
        <ListItem
          actions={[]}
          key={Math.random()}
          metaIcon="CloudDownload"
          hightlightMeta={true}
          onClick={() => {
            window.open(product.url)
          }}
          text={product.filename}
        />
      )
    )
  }

  return (
    <>
      <TehoAppBar
        backgroundColor="transparent"
        color="black"
        logoSize={90}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        text="Laiteluettelo"
        variant="to-home"
      />
      <Container maxWidth="sm">
        {loading && (
          <Spinner
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '50px'
            }}
            variant="defaultLight"
          />
        )}
        {SelectedProduct ? (
          <div key={SelectedProduct.id + Math.random()}>
            <Center>
              <img
                style={{ width: '200px' }}
                src={
                  SelectedProduct.products.fields['Product main image'][0]
                    .thumbnails.large.url
                }
                alt={SelectedProduct.products.fields['Product name']}
              />
            </Center>
            <Heading
              align="left"
              title={SelectedProduct.products.fields['Product name']}
              body={
                SelectedProduct.products.fields['Product category'] +
                ' / ' +
                SelectedProduct.products.fields['Product subcategory']
              }
            />
            <Heading
              align="left"
              body={'' + SelectedProduct.products.fields['Product description']}
            />
            <Heading
              align="left"
              subtitle="Liitteet"
              style={{ fontWeight: 'bold' }}
            />
            {attachments}
            <hr className={classes.line} />
            <ListItem
              maxWidth="sm"
              text="Tuotteen tiedot"
              metaIcon={open ? 'ExpandLess' : 'ExpandMore'}
              onClick={() => setOpen(!open)}
            />
            <Collapse in={open}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Heading subtitle="Sarjanumero" align="left" />
                <Button
                  disabled={
                    serialNumber &&
                    SelectedProduct &&
                    SelectedProduct.serialNumberLocked === true
                  }
                  label={
                    serialNumber
                      ? serialNumber
                      : serialNumber || 'Syötä sarjanumero'
                  }
                  color="primary"
                  onClick={() => setOpenDialog(!openDialog)}
                />
              </div>
            </Collapse>
            <Dialog
              title="Sarjanumero"
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              actionComponents={[
                <Button
                  disabled={loading}
                  label="Peruuta"
                  variant="text"
                  disablePadding
                  onClick={() => setOpenDialog(false)}
                />,
                <Button
                  disabled={loading}
                  label="Tallenna"
                  color="primary"
                  disablePadding
                  onClick={handleSubmit}
                />
              ]}
            >
              {loading ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '10%',
                    marginBottom: '10%'
                  }}
                >
                  <CircularProgress size="30px" color="inherit" />
                </div>
              ) : (
                <TextField
                  type="email"
                  disableGutters
                  autoFocus
                  fullWidth
                  label="Syötä sarjanumero"
                  onChange={event => {
                    setSerialNumber(event.target.value)
                  }}
                  value={serialNumber}
                />
              )}
            </Dialog>
          </div>
        ) : (
          ''
        )}
        <hr className={classes.line} />
        <Button
          fullWidth
          disabled={loading}
          color="primary"
          label="Poista laite listaluettelosta"
          onClick={() => setOpenConfirmationDialog(true)}
        />
        <div style={{ marginBottom: '100px' }}></div>
        <Dialog
          title="Varoitus!"
          subtitle="Oletko varma, että haluat varmasti poistaa tuotteen listaluettelostasi?"
          fullWidth
          maxWidth="xs"
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          actionComponents={[
            <Button
              label="En halua"
              color="secondary"
              disablePadding
              onClick={() => setOpenConfirmationDialog(false)}
            />,
            <Button
              label="Olen varma"
              color="primary"
              disablePadding
              onClick={handleDelete}
            />
          ]}
        />
      </Container>
    </>
  )
}

export default ProductCard
