import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  StatusHero,
  Button,
  Whitelabel,
  FeedCard,
  FeedTitle,
  SpeedDial
} from 'uniqore-components'
import TehoAppBar from 'components/common/TehoAppBar'
const useStyles = makeStyles(() => ({
  speedDial: {
    position: 'fixed',
    right: '20px',
    bottom: '20px'
  }
}))

const AddProduct = () => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <>
      <TehoAppBar
        maxWidth="sm"
        logo="https://uploads-ssl.webflow.com/5fa15e111bdcf3ea29f1fec1/608a549200a19cd1943a1a95_tehomix-logo.svg"
        logoSize={90}
        position="static"
        showScrollHeight={0}
        backgroundColor="#DFDAD6"
        border
        variant="logout"
      />
      <StatusHero
        maxWidth="sm"
        backgroundColor="#DFDAD6"
        color="#1c1c1c"
        actionComponent={
          <Button
            icon="Add"
            label="Lisää laite"
            role="status"
            onClick={() => {
              history.push('/product-category')
            }}
          />
        }
        headline="Tervetuloa"
        subtitle="Siirry lisäämään omia laitteita laitekantaasi tai tilaa huolto laitteellesi."
      />
      <Button
        maxWidth="sm"
        color="primary"
        icon="ArrowForward"
        label="Tilaa huolto"
        type="submit"
        underline
        variant="default"
        fullWidth
        onClick={() => history.push('service/select-service')}
      />
      <FeedTitle text="Viimeiset tapahtumat" />
      <FeedCard
        avatarBackgroundColor="#FF6E00"
        date="Today 12:02"
        subtitle="TERVETULOA"
        text="Tervetuloa yritystilillesi! Löydät palvelut oikean alakulman plussan alta."
        user="TEHOMIX OY"
      />
      <div style={{ marginBottom: '100px' }}></div>
      <SpeedDial className={classes.speedDial} />
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default AddProduct
