import React, { useEffect, useState } from 'react'
import Chip from '@material-ui/core/Chip'
import { Container, Heading, Button, TextField } from 'uniqore-components'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import {
  AddComment,
  AddServiceList,
  EditServiceList,
  AddProductForService,
  AddDeliveryMethods,
  AddDeliveryDetails
} from 'store/actions/services'
import TehoAppBar from 'components/common/TehoAppBar'
import { getDeliveryMethodsByProduct } from 'util/services'
import { CircularProgress } from '@material-ui/core'

export type DeliveryDetailsType = {
  firstName: string
  lastName: string
  address: string
  zip: string
  city: string
  phoneNumber: string
}

const ExtraInformation = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const [deliveryDetails, setDeliveryDetails] = useState<DeliveryDetailsType>({
    firstName: '',
    lastName: '',
    address: '',
    zip: '',
    city: '',
    phoneNumber: ''
  })

  const filledDeliveryDetails =
    deliveryDetails?.firstName &&
    deliveryDetails?.lastName &&
    deliveryDetails?.address &&
    deliveryDetails?.zip &&
    deliveryDetails?.city &&
    deliveryDetails?.phoneNumber
  const { SelectedProduct } = useSelector((state: AppState) => state.product)
  const {
    SelectedServices,
    SelectedProductForService,
    ServiceList
  } = useSelector((state: AppState) => state.services)

  const [deliveryMethods, setDeliveryMethods] = useState<
    { id: string; option: string }[] | undefined
  >()

  useEffect(() => {
    async function fetchData() {
      if (
        SelectedProduct &&
        SelectedProduct.fields['Product SKU'] &&
        SelectedProduct.fields['Product SKU'].length > 0
      ) {
        const sku = SelectedProduct.fields['Product SKU'][0]
        const deliveries = await getDeliveryMethodsByProduct(sku)

        let deliveryOptions: { id: string; option: string }[] = []

        deliveries.forEach(delivery => {
          const deliveryOption = delivery.fields.Option
          deliveryOptions.push({
            id: delivery.id,
            option: deliveryOption
          })
        })
        setDeliveryMethods(deliveryOptions)
        setLoading(false)
      }
    }
    fetchData()
  }, [SelectedProduct, dispatch])

  const [selectedDelivery, setSelectedDelivery] = useState<
    { option: string; id: string }[] | undefined
  >([])

  const [comment, setComment] = useState<string>(
    SelectedProductForService
      ? SelectedProductForService.Comment
      : SelectedProduct
      ? SelectedProduct.Comment
      : ''
  )

  const handleChange = (event: any) => {
    const Comment = event.target.value
    setComment(Comment)
  }

  const handleSubmit = () => {
    dispatch(AddComment(comment))
    dispatch(AddDeliveryMethods(selectedDelivery))
    dispatch(AddDeliveryDetails(deliveryDetails))
    if (
      SelectedProductForService &&
      (SelectedProductForService !== undefined ||
        SelectedProductForService !== '')
    ) {
      let NewSelectProduct: any = ''
      /* Check if the current and previous selected product are the same
       ** If not, the product has also been changed
       ** Choose the updated one.
       */
      if (
        SelectedProduct.fields['Product SKU'][0] ===
        SelectedProductForService.Product.fields['Product SKU'][0]
      ) {
        NewSelectProduct = SelectedProductForService.Product
      } else {
        NewSelectProduct = SelectedProduct
      }
      dispatch(
        EditServiceList(SelectedProductForService.id, {
          Product: NewSelectProduct,
          SelectedServices: SelectedServices,
          Comment: comment,
          id: SelectedProductForService.id
        })
      )
    } else {
      dispatch(
        AddServiceList({
          Product: SelectedProduct,
          SelectedServices: SelectedServices,
          Comment: comment,
          id: SelectedProduct.id
        })
      )
    }
    dispatch(AddProductForService(SelectedProduct.id))
    history.push('service-list')
  }

  const isSelected = (v: string | undefined) => {
    if (v) {
      return !selectedDelivery
        ? deliveryMethods?.some(
            (method: { option: string }) => method.option === v
          )
        : selectedDelivery
        ? selectedDelivery.some(method => method.option === v)
        : false
    }
  }

  const unSelect = (v: string | undefined) => {
    if (v) {
      return !selectedDelivery
        ? deliveryMethods?.filter((method: { option: string }) => {
            return method.option !== v
          })
        : selectedDelivery
        ? selectedDelivery.filter((method: { option: string }) => {
            return method.option !== v
          })
        : []
    }
  }

  const handleClick = (method: { option: string; id: string }) => {
    if (method) {
      setSelectedDelivery(
        isSelected(method?.option ? method.option : undefined)
          ? unSelect(method?.option ? method.option : undefined)
          : () => {
              return [method]
            }
      )
    }
  }
  const optionForDeliveryAddress =
    'Toivon Tehomixin tilaavan kuljetuksen tuotteen noutoa varten'
  const selectedDeliveryOption =
    selectedDelivery?.length && selectedDelivery[0].option
      ? selectedDelivery[0].option
      : ''

  return (
    <>
      <TehoAppBar
        backgroundColor="transparent"
        color="black"
        logo=""
        logoSize={90}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        text="Palvelu"
        border
        variant={ServiceList ? 'to-home-warning' : 'to-home'}
      />
      <Container>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          {SelectedProduct ? (
            <Chip
              color="primary"
              label={SelectedProduct.fields['Product name']}
            />
          ) : (
            ''
          )}
        </div>
        <Heading align="center" title="Toimitustavat" />
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '10%',
              marginBottom: '10%'
            }}
          >
            <CircularProgress size="30px" color="inherit" />
          </div>
        ) : (
          deliveryMethods?.map((method: { id: string; option: string }) => {
            return (
              <Button
                key={method.id}
                label={method.option}
                value={method.option}
                role="checkbox"
                checked={isSelected(method.option)}
                onClick={e => handleClick(method)}
              />
            )
          })
        )}
        {optionForDeliveryAddress.toLowerCase() ===
          selectedDeliveryOption.toLowerCase() && (
          <>
            <Heading align="center" title="Noudon yhteystiedot" />
            <TextField
              label="Etunimi"
              fullWidth
              type="text"
              value={deliveryDetails?.firstName ?? ''}
              onChange={e =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  firstName: e.target.value
                })
              }
            />
            <TextField
              label="Sukunimi"
              fullWidth
              type="text"
              value={deliveryDetails?.lastName ?? ''}
              onChange={e =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  lastName: e.target.value
                })
              }
            />
            <TextField
              fullWidth
              label="Katuosoite"
              type="text"
              value={deliveryDetails?.address ?? ''}
              onChange={e =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  address: e.target.value
                })
              }
            />
            <TextField
              fullWidth
              label="Postinumero"
              type="text"
              value={deliveryDetails?.zip ?? ''}
              onChange={e =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  zip: e.target.value
                })
              }
            />
            <TextField
              fullWidth
              label="Postitoimipaikka"
              type="text"
              value={deliveryDetails?.city ?? ''}
              onChange={e =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  city: e.target.value
                })
              }
            />
            <TextField
              fullWidth
              label="Puhelinnumero"
              type="text"
              value={deliveryDetails?.phoneNumber ?? ''}
              onChange={e =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  phoneNumber: e.target.value
                })
              }
            />
          </>
        )}
        <Heading
          align="center"
          title="Lisätiedot"
          body="Tarkenna alle huolto- tai korjaustarvetta, jotta pääsemme nopeammin asiaan kiinni."
        />
        <TextField
          fullWidth
          label="Lisätiedot, esim. ongelman kuvaus"
          rows={5}
          type="text"
          value={comment}
          onChange={handleChange}
        />
        <div style={{ marginBottom: '100px' }}></div>
        <Button
          style={{ position: 'absolute', bottom: '40px' }}
          disabled={
            selectedDelivery?.length === 0 ||
            (!filledDeliveryDetails &&
              optionForDeliveryAddress.toLowerCase() ===
                selectedDeliveryOption.toLowerCase())
              ? true
              : false
          }
          color="primary"
          fullWidth
          label="Eteenpäin"
          type="submit"
          variant="contained"
          maxWidth="sm"
          onClick={handleSubmit}
        />
      </Container>
    </>
  )
}

export default ExtraInformation
