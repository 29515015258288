import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { SessionStatus } from 'store/actions/common'
import { AppState } from 'types/types'
import AddProduct from './components/product/AddProduct'
import ProductCategory from './components/product/ProductCategory'
import ProductSubCategory from './components/product/ProductSubCategory'
import ProductCatalog from './components/product/ProductCatalog'
import ProductList from './components/product/ProductList'
import ProductMessage from './components/product/ProductMessage'
import ProductCard from './components/product/ProductCard'
import ProductDetails from './components/product/ProductDetails'
import PrivateRoute from './components/auth/PrivateRoute'
import CustomerLogin from './components/auth/CustomerLogin'
import CustomerLoginDialog from './components/auth/CustomerLoginDialog'
import ResetPassword from './components/auth/ResetPassword'
import Layout from './components/layouts/Layout'
import CustomerTabs from './components/customer/CustomerTabs'
import StatusMain from './components/status/StatusMain'
import SelectService from './components/service/SelectService'
import SelectRepair from './components/service/SelectRepair'
import SelectProduct from './components/service/SelectProduct'
import ExtraInformation from './components/service/ExtraInformation'
import ServiceList from './components/service/ServiceList'
import ServiceSammary from './components/service/ServiceSummary'
import ServiceConfirmation from './components/service/ServiceConfirmation'
import CustomerOrder from './components/customer/CustomerOrder'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { sessionStatus } = useSelector((state: AppState) => state.common)
  const pathname = window.location.pathname

  React.useEffect(() => {
    if (!localStorage.getItem('token')) {
      dispatch(SessionStatus(false))
    }
  }, [dispatch, pathname])

  return (
    <Router>
      <Layout>
        <Switch>
          {!sessionStatus &&
            pathname !== '/' &&
            pathname !== '/login' &&
            pathname !== '/reset-password' && <CustomerLoginDialog />}
          <Route exact path="/login" render={() => <CustomerLogin />} />
          <Route
            exact
            path="/reset-password"
            render={() => <ResetPassword />}
          />
          <PrivateRoute exact path="/" component={CustomerLogin} />
          <PrivateRoute
            exact
            path="/customer/orders"
            component={() => <CustomerTabs tab="orders" />}
          />
          <PrivateRoute
            exact
            path="/customer/order"
            component={() => <CustomerOrder />}
          />
          <PrivateRoute
            exact
            path="/customer/products"
            component={() => <CustomerTabs tab="products" />}
          />
          <PrivateRoute
            exact
            path="/customer/profile"
            component={() => <CustomerTabs tab="profile" />}
          />
          <PrivateRoute exact path="/add-product" component={AddProduct} />
          <PrivateRoute
            exact
            path="/product-category"
            component={ProductCategory}
          />
          <PrivateRoute
            exact
            path="/product-sub-category"
            component={ProductSubCategory}
          />
          <PrivateRoute
            exact
            path="/product-catalog"
            component={ProductCatalog}
          />
          <PrivateRoute exact path="/product-card" component={ProductCard} />
          <PrivateRoute
            exact
            path="/product-details"
            component={ProductDetails}
          />
          <PrivateRoute exact path="/product-list" component={ProductList} />
          <PrivateRoute
            exact
            path="/product-message"
            component={ProductMessage}
          />
          <PrivateRoute
            exact
            path="/service/select-product"
            component={() => <SelectProduct />}
          />
          <PrivateRoute
            exact
            path="/service/select-service"
            component={() => <SelectService />}
          />
          <PrivateRoute
            exact
            path="/service/select-repair"
            component={() => <SelectRepair />}
          />
          <PrivateRoute
            exact
            path="/service/extra-information"
            component={() => <ExtraInformation />}
          />
          <PrivateRoute
            exact
            path="/service/service-list"
            component={() => <ServiceList />}
          />
          <PrivateRoute
            exact
            path="/service/service-summary"
            component={() => <ServiceSammary />}
          />
          <PrivateRoute
            exact
            path="/service/repair-information"
            component={() => <SelectService />}
          />
          <PrivateRoute
            exact
            path="/service/order-confirmation"
            component={() => <ServiceConfirmation />}
          />
          <PrivateRoute
            exact
            path="/status/welcome"
            component={() => <StatusMain statusType="welcome" />}
          />
          <PrivateRoute
            exact
            path="/status/product"
            component={() => <StatusMain statusType="product" />}
          />
          <PrivateRoute
            exact
            path="/status/repair"
            component={() => <StatusMain statusType="repair" />}
          />
        </Switch>
      </Layout>
    </Router>
  )
}

export default App
