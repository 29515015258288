import { AppActions, ProductActionType } from 'types/types'
import { Dispatch } from 'redux'

export const ADD_CATEGORY = 'ADD_CATEGORY'
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'
export const SELECT_PRODUCT = 'SELECT_PRODUCT'
export const CLIENT_PRODUCTS_UPDATED = 'CLIENT_PRODUCTS_UPDATED'

export const AddCategory = (Category: string) => (
  dispatch: Dispatch<AppActions>
): ProductActionType => {
  return dispatch({
    type: ADD_CATEGORY,
    payload: { Category }
  })
}

export const AddSubCategory = (SubCategory: string) => (
  dispatch: Dispatch<AppActions>
): ProductActionType => {
  return dispatch({
    type: ADD_SUB_CATEGORY,
    payload: { SubCategory }
  })
}

export const AddProduct = (Products: any) => (
  dispatch: Dispatch<AppActions>
): ProductActionType => {
  return dispatch({
    type: ADD_PRODUCT,
    payload: { Products }
  })
}

export const SelectProduct = (SelectedProduct: any) => (
  dispatch: Dispatch<AppActions>
): ProductActionType => {
  return dispatch({
    type: SELECT_PRODUCT,
    payload: { SelectedProduct }
  })
}

export const DeleteProduct = (id: string) => (
  dispatch: Dispatch<AppActions>
): ProductActionType => {
  return dispatch({
    type: REMOVE_PRODUCT,
    payload: { id }
  })
}

export const ClearProduct = () => (
  dispatch: Dispatch<AppActions>
): ProductActionType => {
  return dispatch({
    type: CLEAR_PRODUCT
  })
}

export const SetClientProductsUpdated = (ClientProductsUpdated: boolean) => (
  dispatch: Dispatch<AppActions>
): ProductActionType => {
  return dispatch({
    type: CLIENT_PRODUCTS_UPDATED,
    payload: { ClientProductsUpdated }
  })
}