import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppBar, Button, Dialog } from 'uniqore-components'
import { Props as AppBarProps } from 'uniqore-components/dist/components/AppBar/AppBar'
import {
  ClearServiceList,
  ClearSelectedProductForService
} from 'store/actions/services'
import { ClearProduct } from 'store/actions/product'

interface ToHomeDialogProps {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}

const ToHomeDialog: React.FC<ToHomeDialogProps> = props => {
  return (
    <Dialog
      title="Haluatko varmasti siirtyä etusivulle?"
      subtitle="Kaikki kesken jääneen tilauksen tiedot jäävät tallentamatta."
      fullWidth
      maxWidth="xs"
      open={props.open}
      onClose={() => props.onCancel()}
      actionComponents={[
        <Button
          label="Sulje"
          color="secondary"
          disablePadding
          onClick={() => props.onCancel()}
        />,
        <Button
          label="Siirry etusivulle"
          color="primary"
          disablePadding
          onClick={() => props.onConfirm()}
        />
      ]}
    />
  )
}

export interface TehoAppBarProps extends AppBarProps {
  /**
   * 'logout': logs out the user
   * 'to-home': redirects to the home page
   * 'to-home-warning': redirects to the home page and shows a warning
   */
  variant?: 'logout' | 'to-home' | 'to-home-warning'
}

const TehoAppBar: React.FC<TehoAppBarProps> = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { actions, ...rest } = props
  const actionList = actions || []
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)

  const logout = () => {
    // Remove all items from localStorage just to be safe
    localStorage.clear()
    history.push('/login')
  }

  const toHome = () => {
    setOpenDialog(false)
    dispatch(ClearSelectedProductForService())
    dispatch(ClearServiceList())
    dispatch(ClearProduct())
    history.push('/status/product')
  }

  const toHomeWarning = () => {
    setOpenDialog(true)
  }

  const cancelToHome = () => {
    setOpenDialog(false)
  }

  const variantClick = () => {
    switch (props.variant) {
      case 'logout':
        logout()
        break
      case 'to-home':
        toHome()
        break
      case 'to-home-warning':
        toHomeWarning()
        break
      default:
        break
    }
  }

  if (props.variant) {
    actionList.push({
      icon: props.variant === 'logout' ? 'ExitToApp' : 'Close',
      onClick: () => variantClick()
    })
  }

  return (
    <>
      <AppBar actions={actionList} {...rest} />
      <ToHomeDialog
        open={openDialog}
        onCancel={cancelToHome}
        onConfirm={toHome}
      />
    </>
  )
}

export default TehoAppBar
