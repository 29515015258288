import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Whitelabel,
  Heading,
  ListItem,
  Button,
  Dialog
} from 'uniqore-components'
import { CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'types/types'
import { SelectProduct } from 'store/actions/product'
import { getClientProducts } from 'util/products'
import { ClientProductRecord } from 'graphql/types'
import { SessionStatus } from 'store/actions/common'
import TehoAppBar from 'components/common/TehoAppBar'

const ProductList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const { SelectedProductForService } = useSelector(
    (state: AppState) => state.services
  )

  const handleProductClick = (clientProduct: any) => {
    if (SelectedProductForService) {
      setConfirmOpen(true)
    } else if (clientProduct && !SelectedProductForService) {
      dispatch(SelectProduct(clientProduct))
      history.push('/service/select-service')
    }
  }

  const handleConfirm = () => {
    setConfirmOpen(false)
    history.push('/service/service-summary')
  }

  const [productsList, setProductsList] = React.useState<
    ClientProductRecord[]
  >()
  useEffect(() => {
    async function fetchData() {
      const data = await getClientProducts()
      setProductsList(data)
      setLoading(false)
    }
    fetchData().catch(() => dispatch(SessionStatus(false)))
  }, [dispatch])

  return (
    <>
      <TehoAppBar
        maxWidth="sm"
        text="Etusivulle"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        border
        variant="to-home-warning"
      />
      <Container maxWidth="sm">
        <Heading
          align="center"
          body='Jos laitteesi ei löydy listasta, niin voit lisätä laitteen valitsemalla "Lisää uusi laite".'
          title="Valitse huollettava laite"
        />
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '10%',
              marginBottom: '10%'
            }}
          >
            <CircularProgress size="30px" color="inherit" />
          </div>
        ) : productsList ? (
          productsList.map((product: any) => (
            <ListItem
              key={product.fields['Product SKU'] + Math.random()}
              image={
                product.fields['Product main image'][0].thumbnails.small.url
              }
              metaIcon="ArrowForward"
              onClick={() => handleProductClick(product)}
              subtitles={[
                product.fields['Product brand'],
                product.fields['Product serial number']
                  ? product.fields['Product SKU'] +
                    ' - (' +
                    product.fields['Product serial number'] +
                    ')'
                  : product.fields['Product SKU']
              ]}
              text={product.fields['Product name']}
            />
          ))
        ) : (
          ''
        )}
        <Button
          color="secondary"
          icon="Add"
          label="Lisää uusi laite"
          metaText=""
          onClick={() => {
            history.push('/product-category')
          }}
          role="radio"
          type="submit"
          variant="default"
        />
        <div style={{ marginBottom: '100px' }}></div>
      </Container>
      <Dialog
        actionComponents={[
          <Button
            color="primary"
            disablePadding
            label="Sulje"
            onClick={() => handleConfirm()}
          />
        ]}
        fullWidth
        maxWidth="sm"
        open={confirmOpen}
        onClose={() => handleConfirm}
        subtitle="Sinulla on jo huoltotilaukseen valittuna tuote. Ole hyvä ja jatka tämän tuotteen kanssa, taikka siirry etusivulle."
        title="Huom!"
      />
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ProductList
