import React, { useEffect } from 'react'
import { ListItem, Heading, Button } from 'uniqore-components'
import { CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { SelectProduct } from 'store/actions/product'
import { getClientProducts } from 'util/products'
import { ClientProductRecord } from 'graphql/types'
import { useHistory } from 'react-router-dom'
import { SessionStatus } from 'store/actions/common'

const Products = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState<boolean>(true)

  const handleProductClick = (product: any) => {
    if (product) {
      dispatch(SelectProduct(product))
      history.push('/product-card')
    }
  }

  const [productsList, setProductsList] = React.useState<
    ClientProductRecord[]
  >()
  useEffect(() => {
    async function fetchData() {
      const data = await getClientProducts()
      setProductsList(data)
      setLoading(false)
    }
    fetchData().catch(() => dispatch(SessionStatus(false)))
  }, [dispatch])

  return (
    <>
      <Heading align="center" title="Laiteluettelo" />
      {loading ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: '10%',
            marginBottom: '10%'
          }}
        >
          <CircularProgress size="30px" color="inherit" />
        </div>
      ) : productsList ? (
        productsList.map((product: any) => (
          <ListItem
            key={product.fields['Product SKU'] + Math.random()}
            image={product.fields['Product main image'][0].thumbnails.small.url}
            metaIcon="ArrowForward"
            onClick={() =>
              handleProductClick({
                products: product,
                serialNumber: product.fields['Product serial number'],
                serialNumberLocked:
                  product.fields['Product serial number locked']
              })
            }
            subtitles={[
              product.fields['Product brand'],
              product.fields['Product serial number']
                ? product.fields['Product SKU'] +
                  ' - (' +
                  product.fields['Product serial number'] +
                  ')'
                : product.fields['Product SKU']
            ]}
            text={product.fields['Product name']}
          />
        ))
      ) : (
        ''
      )}
      <Button
        color="secondary"
        customColor={{
          backgroundColor: '',
          color: ''
        }}
        icon="Add"
        label="Lisää uusi laite"
        metaText=""
        onClick={() => {
          history.push('/product-category')
        }}
        role=""
        type="submit"
        variant="contained"
        fullWidth
      />
    </>
  )
}

export default Products
