import React from 'react'
import Chip from '@material-ui/core/Chip'
import { Container, Heading, Button, Dialog } from 'uniqore-components'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import { getServicesByProduct } from 'util/services'
import { useEffect, useState } from 'react'
import { ServiceRecord } from 'graphql/types'
import {
  AddServices,
  ClearServiceList,
  ClearSelectedProductForService
} from 'store/actions/services'
import { ClearProduct } from 'store/actions/product'
import { SessionStatus } from 'store/actions/common'
import TehoAppBar from 'components/common/TehoAppBar'

const SelectService = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const { SelectedProduct } = useSelector((state: AppState) => state.product)
  const { SelectedProductForService, ServiceList } = useSelector(
    (state: AppState) => state.services
  )

  if (!SelectedProduct) history.push('/service/select-product')
  const categoryText: any = () => {
    return SelectedProduct
      ? SelectedProduct.fields['Category'] +
          ' / ' +
          SelectedProduct.fields['Sub-category']
      : ''
  }

  const [services, setServices] = useState<[]>()
  useEffect(() => {
    async function fetchData() {
      if (
        SelectedProduct &&
        SelectedProduct.fields['Product SKU'] &&
        SelectedProduct.fields['Product SKU'].length > 0
      ) {
        const sku = SelectedProduct.fields['Product SKU'][0]
        const data = await getServicesByProduct(sku)
        let serviceCategories: any = []
        data.forEach(service => {
          const category = service.fields.Category
          serviceCategories[category]
            ? serviceCategories[category].push(service)
            : (serviceCategories[category] = [service])
        })
        setServices(serviceCategories)
        setLoading(false)
      }
    }
    fetchData().catch(() => dispatch(SessionStatus(false)))
  }, [SelectedProduct, dispatch])

  const handleClick = (services: ServiceRecord[]) => {
    if (services[0].fields.Issue !== undefined) {
      history.push('/service/select-repair', { services })
    } else {
      dispatch(AddServices(services))
      history.push('extra-information')
    }
  }

  const serviceButtons: Object[] = []
  const categories: any[] = []
  if (services) {
    for (var category in services) {
      categories.push(category)
    }
    // Sort array to Takuukorjaus → Korjaus → Kalibration
    const newArr = categories.sort()
    const t = newArr.pop()
    newArr.reverse()
    newArr.unshift(t)
    categories.forEach(function (cat) {
      const categoryServices = services[cat]
      let SelectedCategory = ''
      /* Check if there is a product selected for service
       ** and this product is the same as the selected product from select-product page
       ** then the Button should be checked
       */
      if (
        SelectedProductForService &&
        SelectedProductForService.Product.fields['Product SKU'][0] ===
          SelectedProduct.fields['Product SKU'][0]
      ) {
        SelectedCategory =
          SelectedProductForService.SelectedServices[0].fields.Category
      }
      serviceButtons.push(
        <Button
          checked={cat === SelectedCategory}
          key={cat}
          role="radio"
          label={cat}
          value={cat}
          onClick={() => handleClick(categoryServices)}
        />
      )
    })
  }

  return (
    <>
      <TehoAppBar
        backgroundColor="transparent"
        color="black"
        logoSize={90}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () =>
            SelectedProductForService ? setOpenDialog(true) : history.goBack()
        }}
        position="static"
        showScrollHeight={0}
        text="Laiteluettelo"
        border
        variant={ServiceList ? 'to-home-warning' : 'to-home'}
      />
      <Container>
        <div
          style={{
            textAlign: 'center',
            marginTop: '10px'
          }}
        >
          {SelectedProduct ? (
            <Chip
              color="primary"
              label={SelectedProduct.fields['Product name']}
            />
          ) : (
            ''
          )}
        </div>
        <Heading align="center" title="Valitse palvelu" body={categoryText} />
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '10%',
              marginBottom: '10%'
            }}
          >
            <CircularProgress size="30px" color="inherit" />
          </div>
        ) : serviceButtons ? (
          serviceButtons
        ) : (
          ''
        )}
      </Container>
      <Container>
        <Dialog
          subtitle="Haluatko varmasti palata takaisin? Kaikki tallennetut tiedot menetetään."
          fullWidth
          open={openDialog}
          actionComponents={[
            <Button
              label="Peruuta"
              variant="text"
              color="primary"
              disablePadding
              onClick={() => {
                setOpenDialog(false)
              }}
            />,
            <Button
              label="Kyllä"
              color="primary"
              disablePadding
              onClick={() => {
                setOpenDialog(false)
                dispatch(ClearSelectedProductForService())
                dispatch(ClearServiceList())
                dispatch(ClearProduct())
                history.goBack()
              }}
            />
          ]}
          onClose={() => setOpenDialog(false)}
        />
      </Container>
    </>
  )
}

export default SelectService
