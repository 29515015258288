import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Whitelabel,
  Heading,
  ListItem
} from 'uniqore-components'
import { CircularProgress } from '@material-ui/core'
import SearchDialogComponent from './SearchDialogComponent'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import { SelectProduct } from 'store/actions/product'
import { getProductsSearchByCategory } from 'util/products'
import { ProductRecord } from 'graphql/types'
import { SessionStatus } from 'store/actions/common'
import TehoAppBar from 'components/common/TehoAppBar'

const ProductCatalog = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const { SelectedCategory, SelectedSubCategory } = useSelector(
    (state: AppState) => state.product
  )

  const handleClick = (SKU: string) => {
    dispatch(SelectProduct(SKU))
    history.push('/product-details')
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const [productsList, setProductsList] = React.useState<ProductRecord[]>()
  useEffect(() => {
    async function fetchData() {
      if (SelectedCategory && SelectedSubCategory) {
        const data = await getProductsSearchByCategory(
          SelectedCategory!,
          SelectedSubCategory!
        )
        setProductsList(data)
        setLoading(false)
      }
    }
    fetchData().catch(() => dispatch(SessionStatus(false)))
  }, [SelectedCategory, SelectedSubCategory, dispatch])

  return (
    <>
      <TehoAppBar
        maxWidth="sm"
        text={SelectedCategory}
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        border
        actions={[
          {
            icon: 'Search',
            onClick: toggleOpen
          }
        ]}
        variant="to-home"
      />
      <Container maxWidth="sm">
        <Heading align="center" title={SelectedSubCategory} />
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '30%'
            }}
          >
            <CircularProgress size="30px" color="inherit" />
          </div>
        ) : productsList ? (
          productsList.map(product => (
            <ListItem
              key={product.fields.SKU + Math.random()}
              image={product.fields['Main image'][0].thumbnails.small.url}
              metaIcon="ArrowForward"
              onClick={() => handleClick(product.fields.SKU)}
              subtitles={[product.fields.Brand, product.fields.SKU]}
              text={product.fields.Name}
            />
          ))
        ) : (
          ''
        )}
        <div style={{ marginBottom: '60px' }}></div>
        <SearchDialogComponent open={open} onClose={toggleOpen} />
      </Container>
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ProductCatalog
