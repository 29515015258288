import React, { useState } from 'react'
import { Onboarding, Whitelabel, Button, Container } from 'uniqore-components'
import { ClearServiceList } from 'store/actions/services'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'
import ServiceMessage from '../../assets/SVGs/img-success.svg'
import { OrderRecord } from 'graphql/types'
import { SessionStatus } from 'store/actions/common'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import PublicClient from 'api/public'
import { GET_ORDERS_QUERY } from 'graphql/queries'

const ServiceConfirmation = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, loading, error, refetch } = useQuery(GET_ORDERS_QUERY, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  let orders: OrderRecord[] = []
  const [orderId, setOrderId] = useState('')

  const getOrders = async () => {
    const response = await data
    if (response) {
      orders = response.serviceEngagements
    }
    if (
      orders &&
      orders[orders.length - 1]?.metadata?.some(meta => meta.key === 'orderId')
    ) {
      await getOrderId(orders)
    }
  }

  const getOrderId = async (orders: OrderRecord[]) => {
    orders.forEach((order: any) => {
      order.metadata.forEach((newMeta: any) => {
        if (newMeta.key === 'orderId') {
          setOrderId(String(newMeta.value.data.records[0].fields.ID))
        }
      })
    })
  }

  if (!orderId && data) {
    getOrders()
    if (orderId === '') {
      refetch()
    }
  }

  if (error) {
    dispatch(SessionStatus(false))
  }

  return (
    <>
      {loading && !orderId ? (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <CircularProgress style={{}} size="24px" color="inherit" />
        </Container>
      ) : orderId ? (
        <Onboarding
          screens={[
            {
              image: ServiceMessage,
              title: 'Tilaus lähetetty',
              subtitle: `Olemme lähettäneet sinulle vahvistusviestin sähköpostiisi tilauksellesi #${orderId}. Siirry eteenpäin omille sivuille.`
            }
          ]}
          showIndicators={false}
          actionComponents={[
            <Button
              color="primary"
              label="Siirry etusivulle"
              type="submit"
              onClick={() => {
                dispatch(ClearServiceList())
                history.push('/status/repair')
              }}
            />
          ]}
        />
      ) : (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <CircularProgress style={{}} size="24px" color="inherit" />
        </Container>
      )}
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ServiceConfirmation
