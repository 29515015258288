import { StatusHero, Button } from 'uniqore-components'
import { useHistory } from 'react-router-dom'
import TehoAppBar from 'components/common/TehoAppBar'

const Product = () => {
  const history = useHistory()
  return (
    <>
      <TehoAppBar
        logo="https://uploads-ssl.webflow.com/5fa15e111bdcf3ea29f1fec1/608a549200a19cd1943a1a95_tehomix-logo.svg"
        logoSize={90}
        position="static"
        showScrollHeight={0}
        backgroundColor="#f6f6f6"
        maxWidth="sm"
        variant="logout"
      />
      <StatusHero
        backgroundColor="#f6f6f6"
        color="#000"
        actionComponent={
          <Button
            label="Siirry tilaamaan"
            icon="ArrowForward"
            role="status"
            onClick={() => {
              history.push('/service/select-product')
            }}
          />
        }
        headline={`Tervetuloa, ${localStorage.getItem('firstName')}!`}
        image=""
        maxWidth="sm"
        subtitle="Siirry lisäämään omia laitteita laitekantaasi tai tilaa huolto laitteellesi."
      />
      <Button
        color="primary"
        icon="List"
        label="Laitetiedot ja profiili"
        type="submit"
        underline
        variant="default"
        fullWidth
        maxWidth="sm"
        onClick={() => {
          history.push('/customer/products')
        }}
      />
    </>
  )
}

export default Product
