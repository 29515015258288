import React from 'react'
import { StatusHero, Button } from 'uniqore-components'
import { useHistory } from 'react-router-dom'
import TehoAppBar from 'components/common/TehoAppBar'

const Welcome = () => {
  const history = useHistory()
  return (
    <>
      <TehoAppBar
        logo="https://uploads-ssl.webflow.com/5fa15e111bdcf3ea29f1fec1/608a549200a19cd1943a1a95_tehomix-logo.svg"
        logoSize={90}
        position="static"
        showScrollHeight={0}
        backgroundColor="#f6f6f6"
        maxWidth="sm"
        variant="logout"
      />
      <StatusHero
        backgroundColor="#f6f6f6"
        color="#000"
        actionComponent={
          <Button
            label="Lisää laite"
            icon="Add"
            role="status"
            onClick={() => {
              history.push('/product-category')
            }}
          />
        }
        headline={`Tervetuloa, ${localStorage.getItem('firstName')}!`}
        image=""
        maxWidth="sm"
        subtitle="Kiitokset, että olet aloittanut Tehomix Pro palvelun käytön."
      />
      <Button
        maxWidth="sm"
        color="primary"
        icon="ArrowForward"
        label="Tilaa huolto"
        type="submit"
        underline
        variant="default"
        fullWidth
        onClick={() => history.push('/service/select-product')}
      />
    </>
  )
}

export default Welcome
