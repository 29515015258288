import produce from 'immer'
import { ProductState, ProductActionType } from 'types/types'
import {
  ADD_CATEGORY,
  ADD_SUB_CATEGORY,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  CLEAR_PRODUCT,
  SELECT_PRODUCT,
  CLIENT_PRODUCTS_UPDATED
} from 'store/actions/product'

const initialState: ProductState = {
  ProductList: '',
  SelectedCategory: '',
  SelectedSubCategory: '',
  SelectedProduct: '',
  ClientProductsUpdated: false
}

const product = (state = initialState, action: ProductActionType) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return { ...state, SelectedCategory: action.payload.Category }
    case ADD_SUB_CATEGORY:
      return { ...state, SelectedSubCategory: action.payload.SubCategory }
    case ADD_PRODUCT:
      return {
        ...state,
        ProductList: [action.payload.Products, ...state.ProductList]
      }
    case REMOVE_PRODUCT:
      return produce(state, draft => {
        draft.ProductList = state.ProductList.filter(
          (record: any) => record.id !== action.payload.id
        )
      })
    case CLEAR_PRODUCT:
      return initialState
    case SELECT_PRODUCT:
      return { ...state, SelectedProduct: action.payload.SelectedProduct }
    case CLIENT_PRODUCTS_UPDATED:
      return { ...state, ClientProductsUpdated: action.payload.ClientProductsUpdated }
    default:
      return state
  }
}

export default product
