import { Heading, AppBar, ListItem, Container } from 'uniqore-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory, useLocation } from 'react-router-dom'
import { OrderLine } from 'graphql/types'
import React, { useEffect, useState } from 'react'
import { getOrderLines } from 'util/orders'
import format from 'date-fns/format'

const CustomerOrder = () => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const { state }: any = useLocation()
  const order: any = state.order
  const [orderLines, setOrderlines] = useState<OrderLine[]>()
  const processingStatus = 'Tilaus vastaanotettu, toimita huoltoon'
  const verifiedStatus = 'Tilaus vahvistettu, toimita huoltoon'
  const receivedStatus = 'Laite vastaanotettu, odottaa huoltoa'
  const offeredStatus = 'Huolto aloitettu'
  const readyStatus = 'Huolto valmis'
  const doneStatus = 'Tilaus valmis'
  const rejectedStatus = 'Tilaus peruttu'
  const [orderData, setOrderData] = useState<any>({})

  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      let orderId = ''
      order.metadata.forEach((meta: any) => {
        if (meta.key === '@airtable') {
          orderId = meta.value
        } else if (meta.key === 'Completion time') {
          orderData['Completion time'] = meta.value
          setOrderData(orderData)
        } else if (meta.key === 'Service ID') {
          orderData['Service ID'] = meta.value
          setOrderData(orderData)
        }
      })
      if (orderId) {
        const data = await getOrderLines(orderId)
        if (data) {
          setLoading(false)
        }
        setOrderlines(data)
      }
    }
    fetchData()
  }, [order.metadata, orderData])
  const formatDate = (date: string) => {
    return date ? String(format(Date.parse(date), 'dd.MM.yyyy')) : ''
  }
  const getOrderDate = () => {
    const defaultText = 'Tarkennetaan esiselvityksen pohjalta'
    let date = orderData
      ? formatDate(orderData['Completion time'])
      : defaultText
    if (!date && orderLines) {
      date = orderLines[0].fields['Estimated time of completion']
        ? formatDate(orderLines[0].fields['Estimated time of completion'])
        : defaultText
    }
    return date
  }

  return (
    <>
      <AppBar
        backgroundColor="transparent"
        color="black"
        logo=""
        text="Tapahtumat"
        logoSize={90}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
      />
      {loading ? (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <CircularProgress
            style={{ marginBottom: 20 }}
            size="24px"
            color="inherit"
          />
        </Container>
      ) : (
        <div>
          <Container maxWidth="sm">
            <Heading
              align="left"
              title={
                'Tilaus #' + (orderLines ? orderLines[0].fields.OrderId[0] : '')
              }
              subtitle={
                orderLines
                  ? 'Tila: ' + orderLines[0].fields.Status[0] === 'Processing'
                    ? processingStatus
                    : orderLines[0].fields.Status[0] === 'Received'
                    ? receivedStatus
                    : orderLines[0].fields.Status[0] === 'Ready'
                    ? readyStatus
                    : orderLines[0].fields.Status[0] === 'Done'
                    ? doneStatus
                    : orderLines[0].fields.Status[0] === 'Rejected'
                    ? rejectedStatus
                    : orderLines[0].fields.Status[0] === 'Verified'
                    ? verifiedStatus
                    : orderLines[0].fields.Status[0] === 'Offered'
                    ? offeredStatus
                    : processingStatus
                  : 'Tilapäivitys tulossa pian'
              }
            />
            {orderData && orderData['Service ID'] ? (
              <ListItem
                text={'Huoltonumero'}
                subtitles={[orderData ? orderData['Service ID'] : '']}
              />
            ) : (
              ''
            )}
            <ListItem
              text={orderData['Completion time'] ? 'Valmistumisaika': 'Arvioitu valmistumisaika'}
              subtitles={[getOrderDate()]}
            />
            <hr
              style={{
                marginLeft: '16px',
                marginRight: '16px',
                height: '1px',
                border: 'none',
                background: 'rgba(0, 44, 56, 0.08)'
              }}
            />
          </Container>
          {orderLines
            ? orderLines.map(orderLine => {
                return (
                  <Container maxWidth="sm">
                    <ListItem
                      text={
                        orderLine.fields ? orderLine.fields['Product name'] : ''
                      }
                      image={
                        orderLine.fields
                          ? orderLine.fields['Product attachments'][0]['url']
                          : ''
                      }
                      subtitles={[
                        orderLine.fields
                          ? orderLine.fields['Product brand']
                          : '',
                        orderLine.fields
                          ? orderLine.fields['Product serial number']
                          : ''
                      ]}
                    />
                    {(orderLine.fields['Service issue'] ||
                      orderLine.fields.Comment !== ' ' ||
                      !orderLine.fields.Comment) && (
                      <ListItem
                        text="Lisätiedot"
                        subtitles={[
                          orderLine.fields
                            ? orderLine.fields['Service issue']
                            : '',
                          orderLine.fields
                            ? orderLine.fields.Comment
                            : 'Some test comment'
                        ]}
                      />
                    )}
                    {orderLine.fields['Tasks'] && (
                      <ListItem
                        text="Tehtävät työt"
                        subtitles={[
                          orderLine.fields['Status'][0] === 'Offered' ||
                          orderLine.fields['Status'][0] === 'Ready' ||
                          orderLine.fields['Status'][0] === 'Done'
                            ? orderLine.fields['Tasks']
                            : 'Tarkennetaan esiselvityksen pohjalta'
                        ]}
                      />
                    )}
                    <ListItem
                      text="Palvelu"
                      subtitles={[
                        orderLine.fields['Subcategory'][0] === 'Tasolaserit' &&
                        orderLine.fields['Service category'][0] ===
                          'Kalibrointi'
                          ? orderLine.fields['Service category'] + ' 90,00 € (alv 0%)'
                          : orderLine.fields['Subcategory'][0] ===
                              'Linjalaserit' &&
                            orderLine.fields['Service category'][0] ===
                              'Kalibrointi'
                          ? orderLine.fields['Service category'] + ' 110,00 € (alv 0%)'
                          : orderLine.fields['Costs']
                          ? orderLine.fields['Costs']
                          : orderLine.fields['Service category']
                      ]}
                    />
                    <ListItem
                      text="Huollon sijainti"
                      subtitles={[orderLine.fields['Address'][0]]}
                    />
                    <hr
                      style={{
                        marginLeft: '16px',
                        marginRight: '16px',
                        height: '1px',
                        border: 'none',
                        background: 'rgba(0, 44, 56, 0.08)'
                      }}
                    />
                  </Container>
                )
              })
            : ''}
        </div>
      )}
      <Container maxWidth="sm">
        <hr
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            height: '1px',
            border: 'none',
            background: 'rgba(0, 44, 56, 0.08)'
          }}
        />
        <Heading
          style={{ marginBottom: 30 }}
          body="Korjaustyöt alk.65,00 € / tunti. Olemme yhteyksissä tarkemman arvion osalta. Minimilaskutus 35,00 € + toimituskulut (pl. takuukorjaukset tai kalibrointipalvelut). Hinnat esitetty alv 0%."
        />
      </Container>
    </>
  )
}

export default CustomerOrder
