import PublicClient from 'api/public'
import { GET_PRODUCTS_QUERY, GET_CLIENT_PRODUCTS_QUERY, GET_PRODUCTS_SEARCH_QUERY, GET_PRODUCTS_SEARCH_CATEGORY_QUERY } from 'graphql/queries'
import { ClientProductRecord, ProductRecord, ClientProductsDataViewData, ProductsDataViewData, ProductsSearchDataViewData, ProductsSearchByCategoryDataViewData } from 'graphql/types'

export const getProducts = async (): Promise<ProductRecord[]> => {
  const { data, error } = await PublicClient.query<ProductsDataViewData>({
    query: GET_PRODUCTS_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_PRODUCTS_ID
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}

export const getProductsSearch = async (search: string): Promise<ProductRecord[]> => {
  const { data, error } = await PublicClient.query<ProductsSearchDataViewData>({
    query: GET_PRODUCTS_SEARCH_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_PRODUCTS_SEARCH_ID,
      parameters: { search: search }
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}

export const getProductsSearchByCategory = async (category: string, subCategory: string): Promise<ProductRecord[]> => {
  const { data, error } = await PublicClient.query<ProductsSearchByCategoryDataViewData>({
    query: GET_PRODUCTS_SEARCH_CATEGORY_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_PRODUCTS_SEARCH_BY_CATEGORY_ID,
      parameters: { category, subCategory }
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}

export const getClientProductsSearch = async (search: string): Promise<ClientProductRecord[]> => {
  const { data, error } = await PublicClient.query<ClientProductsDataViewData>({
    query: GET_PRODUCTS_SEARCH_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_CLIENT_PRODUCTS_SEARCH_ID,
      parameters: { search: search }
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}

export const getClientProducts = async (): Promise<ClientProductRecord[]> => {
  const { data, error } = await PublicClient.query<ClientProductsDataViewData>({
    query: GET_CLIENT_PRODUCTS_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_CLIENT_PRODUCTS_ID
    },
    fetchPolicy: 'network-only'
  })
  //if (ClientProductsUpdated) queryData["fetchPolicy"] = 'no-cache'
  //const { data, error } = await PublicClient.query<ClientProductsDataViewData>(queryData)
  if (error) return []

  return data?.fetchDataview.data.records || []
}
