import React, { useState } from 'react'
import { Alert } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import {
  TextField,
  Button,
  Login,
  AppBar,
  Container,
  Dialog
} from 'uniqore-components'
import { useMutation } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { gql } from 'apollo-boost'
import PublicClient from 'api/public'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const resetEmailQuery = gql`
  mutation reset($auth: ID!, $email: String) {
    resetCustomerPassword(auth: $auth, email: $email) {
      ok
      error
    }
  }
`

const resetPasswordQuery = gql`
  mutation changePassword(
    $auth: ID!
    $code: String
    $uid: String
    $password: String
    $passwordRepeat: String
  ) {
    resetCustomerPassword(
      auth: $auth
      code: $code
      uid: $uid
      password: $password
      passwordRepeat: $passwordRepeat
    ) {
      ok
      error
    }
  }
`

const ResetPassword = () => {
  let query = useQuery()

  const code = query.get('code')
  const uid = query.get('uid')
  const activate = query.get('activate')
  const history = useHistory()
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')

  const [isEmailRequest] = useState<boolean>(code !== null)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const [resetEmail] = useMutation(resetEmailQuery, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.resetCustomerPassword.error) {
        setError(mutationResult.resetCustomerPassword.error)
      } else if (mutationResult.resetCustomerPassword.ok) {
        setSuccess('Salasanan vaihtolinkki on lähetetty!')
        setEmail('')
        setLoading(false)
      }
    }
  })

  const [resetPassword] = useMutation(resetPasswordQuery, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.resetCustomerPassword.error) {
        setError(mutationResult.resetCustomerPassword.error)
        setLoading(false)
      } else if (mutationResult.resetCustomerPassword.ok) {
        setLoading(false)
        setPassword('')
        setPasswordRepeat('')
        setOpenDialog(true)
      }
    }
  })

  const validateEmail = (email: string) => {
    const regx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return regx.test(email) ? true : false
  }

  const submit = (event: React.FormEvent) => {
    event.preventDefault()
    setError('')
    if (!isEmailRequest) {
      if (email === '') {
        setError('Syötä sähköpostiosoite!')
      } else {
        if (validateEmail(email)) {
          resetEmail({
            variables: {
              email: email,
              auth: process.env.REACT_APP_CUSTOMER_AUTH_ID
            }
          })
          setLoading(true)
        } else {
          setError('Sähköpostiosoite on virheellinen!')
        }
      }
    } else {
      resetPassword({
        variables: {
          auth: process.env.REACT_APP_CUSTOMER_AUTH_ID,
          code: code,
          uid: uid,
          password: password,
          passwordRepeat: passwordRepeat
        }
      })
      setLoading(true)
    }
  }

  const message = success ? (
    <Alert
      severity="success"
      style={{ margin: '0 15px', color: 'rgba(0, 44, 56, 0.6)' }}
    >
      {success}
    </Alert>
  ) : null

  return (
    <>
      <AppBar
        backgroundColor="transparent"
        color="black"
        logo=""
        maxWidth="sm"
        logoSize={90}
        mainAction={{
          icon: 'ArrowBack',
          onClick: function goBack() {
            history.push('')
          }
        }}
        position="static"
        showScrollHeight={0}
        text="Takaisin"
        border
      />
      <Container>
        {openDialog && (
          <>
            <Dialog
              title="Salasana vaihdettu."
              subtitle="Salasana vaihdettu onnistuneesti."
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              actionComponents={[
                <Button
                  disabled={loading}
                  label="OK"
                  color="primary"
                  disablePadding
                  onClick={() => history.push('/login')}
                />
              ]}
            />
          </>
        )}
        <form onSubmit={submit} style={{ marginTop: '35%' }}>
          {isEmailRequest ? (
            <Login
              headline={activate ? 'Aseta salasana' : 'Vaihda salasana'}
              subtitle="Kirjoita uusi salasana"
              actionComponents={[
                <Button
                  type="submit"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  label={
                    loading ? (
                      <CircularProgress size="24px" color="inherit" />
                    ) : activate ? (
                      'ASETA SALASANA'
                    ) : (
                      'VAIHDA SALASANA'
                    )
                  }
                />
              ]}
              contentComponents={[
                <TextField
                  autoFocus
                  fullWidth
                  placeholder="Salasana"
                  prefix={{ icon: 'LockOutlined' }}
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  errorText={error}
                  type="password"
                />,
                <TextField
                  fullWidth
                  placeholder="Kirjoita salasana uudelleen"
                  prefix={{ icon: 'LockOutlined' }}
                  name="passwordRepeat"
                  value={passwordRepeat}
                  onChange={e => setPasswordRepeat(e.target.value)}
                  errorText={error}
                  type="password"
                />
              ]}
            />
          ) : (
            <Login
              headline="Unohditko salasanasi?"
              subtitle=""
              actionComponents={[
                <Button
                  type="submit"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  label={
                    loading ? (
                      <CircularProgress size="24px" color="inherit" />
                    ) : (
                      'VAIHDA SALASANA'
                    )
                  }
                />
              ]}
              contentComponents={[
                message,
                <TextField
                  autoFocus
                  fullWidth
                  placeholder="Sähköpostiosoite"
                  prefix={{ icon: 'Email' }}
                  name="email"
                  value={email}
                  errorText={error}
                  onChange={e => setEmail(e.target.value)}
                />
              ]}
            />
          )}
        </form>
      </Container>
    </>
  )
}

export default ResetPassword
