import PublicClient from 'api/public'
import { GET_CLIENT_EVENTS_QUERY, GET_CLIENT_DETAILS_QUERY } from 'graphql/queries'
import { ClientEventRecord, ClientEventsDataViewData, ClientDetailsRecord, ClientDetailsDataViewData } from 'graphql/types'

export const getClientEvents = async (): Promise<ClientEventRecord[]> => {
  const { data, error } = await PublicClient.query<ClientEventsDataViewData>({
    query: GET_CLIENT_EVENTS_QUERY,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_CLIENT_EVENTS_ID
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}

export const getClientDetails = async (): Promise<ClientDetailsRecord[]> => {
  const { data, error } = await PublicClient.query<ClientDetailsDataViewData>({
    query: GET_CLIENT_DETAILS_QUERY,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_CLIENT_DETAILS_ID
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}