import React from 'react'
import { useHistory } from 'react-router-dom'
import { Whitelabel, Button, Container, Onboarding } from 'uniqore-components'
import ProductMessage from '../../assets/SVGs/img-added.svg'

const ProductList = () => {
  const history = useHistory()
  return (
    <>
      <Container maxWidth="sm">
        <Onboarding
          screens={[
            {
              image: ProductMessage,
              title: 'Lisäys onnistunut!',
              subtitle:
                'Voit nyt tilata huoltopalveluita laitteillesi. Siirry eteenpäin tilaamaan. Jos poistut, niin laitteet jäävät talteen tilillesi.'
            }
          ]}
          showIndicators={false}
          actionDirection="column"
          actionComponents={[
            <Button
              color="primary"
              label="Eteenpäin"
              type="submit"
              onClick={() => {
                history.push('/status/product')
              }}
            />,
            <Button
              icon="Add"
              color="primary"
              label="Lisää uusi laite"
              variant="text"
              onClick={() => {
                history.push('/product-category')
              }}
            />
          ]}
        />
      </Container>
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ProductList
