import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Whitelabel,
  Heading,
  ListItem,
  Button,
  Container,
  Dialog
} from 'uniqore-components'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import { SelectProduct } from 'store/actions/product'
import {
  AddProductForService,
  RemoveServiceList,
  ClearSelectedProductForService
} from 'store/actions/services'
import { ClearProduct } from 'store/actions/product'
import TehoAppBar from 'components/common/TehoAppBar'

const ServiceList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [orderId, setOrderId] = React.useState<string>('')
  const { ServiceList } = useSelector((state: AppState) => state.services)

  const handleSave = () => {
    history.push('service-summary')
  }

  const handleEdit = (id: string, clientProduct: any) => {
    dispatch(AddProductForService(id))
    dispatch(SelectProduct(clientProduct))
    history.push('/service/select-service')
  }

  const handleCancel = () => {
    setConfirmOpen(false)
  }

  const handleConfirm = () => {
    setConfirmOpen(false)
    if (orderId !== '') {
      dispatch(RemoveServiceList(orderId))
      dispatch(ClearSelectedProductForService())
      dispatch(ClearProduct())
      history.push('/status/product')
    }
  }

  const handleDelete = (id: string) => {
    setOrderId(id)
    if (ServiceList.length > 1) {
      dispatch(RemoveServiceList(id))
    } else {
      setConfirmOpen(true)
    }
  }

  const getServiceTitle = (services: any) => {
    if (services && services.length > 0) {
      return services[0].fields['Category']
    } else {
      return false
    }
  }

  const getIssuesTitle = (services: any) => {
    if (services && services.length > 0) {
      let issues: any = []
      services.forEach((service: any) => {
        issues.push(service.fields['Issue'])
      })
      return issues.join(', ')
    } else {
      return false
    }
  }

  return (
    <>
      <TehoAppBar
        maxWidth="sm"
        text="Lisätiedot"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => history.goBack()
        }}
        position="static"
        showScrollHeight={0}
        border
        variant="to-home-warning"
      />
      <Heading
        maxWidth="sm"
        align="center"
        title="Huoltotiedot"
        body="Tarkista alta huoltotiedot ja siirry eteenpäin."
      />
      {ServiceList
        ? ServiceList.map((service: any) => (
            <ListItem
              maxWidth="sm"
              key={service.id + Math.random()}
              image={service.Product.fields['Product main image'][0].url}
              metaIcon="Edit"
              subtitles={[
                getServiceTitle(service.SelectedServices),
                getIssuesTitle(service.SelectedServices),
                service.Comment
              ]}
              text={service.Product.fields['Product name']}
              actions={[
                {
                  label: 'Muokkaa',
                  icon: 'Edit',
                  onClick: () => handleEdit(service.id, service.Product)
                },
                {
                  label: 'Poista',
                  icon: 'RemoveCircle',
                  onClick: () => {
                    handleDelete(service.id)
                  }
                }
              ]}
            />
          ))
        : ''}
      <Container>
        <Button
          style={{
            position: 'absolute',
            bottom: '40px'
          }}
          disabled={ServiceList.length > 0 ? false : true}
          color="primary"
          fullWidth
          label="Eteenpäin"
          onClick={handleSave}
          type="submit"
          variant="contained"
          maxWidth="sm"
        />
      </Container>
      <Dialog
        actionComponents={[
          <Button
            disablePadding
            label="Peruuta"
            variant="text"
            onClick={() => handleCancel()}
          />,
          <Button
            color="primary"
            disablePadding
            label="Poista"
            onClick={() => handleConfirm()}
          />
        ]}
        fullWidth
        maxWidth="sm"
        open={confirmOpen}
        onClose={() => handleCancel}
        subtitle="Poistetaanko tilaus ja palataan etusivulle?"
        title="Varmistus"
      />
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ServiceList
