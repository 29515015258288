import {
  FeedTitle,
  FeedCard,
  Container,
  Dialog,
  Button
} from 'uniqore-components'
import SpeedDial from './SpeedDial'
import Welcome from './Welcome'
import Product from './Product'
import Repair from './Repair'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from '@apollo/client'
import { getClientEvents } from 'util/client-events'
import { ClientEventRecord } from 'graphql/types'
import { SessionStatus } from 'store/actions/common'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmCookies } from 'store/actions/common'
import { AppState } from 'types/types'
import { GET_FIRSTNAME } from 'graphql/queries'
import PublicClient from 'api/public'

const useStyles = makeStyles(() => ({
  feedCard: {
    '& [class*="MuiButtonBase-root"]': {
      cursor: 'default',
      '& > span': {
        all: 'unset',
        '& > span': {
          all: 'unset'
        }
      }
    }
  }
}))

type PropTypes = {
  statusType: string
}

const StatusMain = (props: PropTypes) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const cookies = useSelector((state: AppState) => state.common.status)
  const { data, loading: dataLoading, error } = useQuery(GET_FIRSTNAME, {
    client: PublicClient
  })

  if (data && !dataLoading) {
    localStorage.setItem('firstName', data.thisCustomer.firstName)
    localStorage.setItem('lastName', data.thisCustomer.lastName)
    localStorage.setItem('email', data.thisCustomer.email)
    localStorage.setItem('phone', data.thisCustomer.phone)
    localStorage.setItem('companyName', data.thisCustomer.companyName)
    localStorage.setItem('address', data.thisCustomer.address)
    localStorage.setItem('zip', data.thisCustomer.zip)
    localStorage.setItem('city', data.thisCustomer.city)
  }

  if (error) {
    history.push('/login')
  }

  const statusComponents: any = {
    welcome: <Welcome />,
    product: <Product />,
    repair: <Repair />
  }
  const status: Object = statusComponents[props.statusType]
  const [loading, setLoading] = useState<boolean>(false)
  const [events, setEvents] = useState<ClientEventRecord[]>()
  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      const data = await getClientEvents()
      if (data) {
        setLoading(false)
      }
      setEvents(data)
    }
    fetchData().catch(() => dispatch(SessionStatus(false)))
  }, [dispatch])
  let feedTitle = []
  let feedCards: Object[] = []
  if (events) {
    if (events.length > 0)
      feedTitle.push(
        <FeedTitle key={Math.random()} text="Viimeiset tapahtumat" />
      )
    events.forEach(event => {
      feedCards.push(
        <FeedCard
          key={Math.random()}
          avatarBackgroundColor={event.fields.AvatarBackgroundColor}
          avatarColor={event.fields.AvatarColor}
          date={event.fields.Date}
          subtitle={event.fields.Subtitle}
          text={event.fields.Text}
          user={event.fields.User}
          className={classes.feedCard}
        />
      )
    })
  }

  const lastFeedcards = feedCards?.slice(0, 10)
  return (
    <>
      {status}
      {loading || dataLoading ? (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <CircularProgress style={{}} size="24px" color="inherit" />
        </Container>
      ) : (
        <div>
          {feedTitle}
          {lastFeedcards}
        </div>
      )}
      <div style={{ marginBottom: '80px' }}></div>
      <SpeedDial />
      <Dialog
        open={localStorage.getItem('cookies') ? false : !cookies}
        maxWidth="xs"
        onClose={() => {
          dispatch(ConfirmCookies(true))
          localStorage.setItem('cookies', 'true')
        }}
        title={`Tervetuloa, ${localStorage.getItem('firstName')}!`}
        fullWidth
        subtitle="Kiitokset, että olet aloittanut Tehomix Pro palvelun käytön."
        actionComponents={[
          <Button
            label="Asia selvä"
            color="primary"
            disablePadding
            onClick={() => {
              dispatch(ConfirmCookies(true))
              localStorage.setItem('cookies', 'true')
            }}
          />
        ]}
      />
    </>
  )
}

export default StatusMain
