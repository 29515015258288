import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'
import { defaultTheme } from 'uniqore-components'

/**
 * https://material-ui.com/customization/default-theme/
 * Material-Ui's default theme is replaced here by Uniqore components library default theme.
 */
const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#FF6E00',
      contrastText: '#fff'
    },
    secondary: {
      main: '#1E1E1E'
    },
    text: {
      primary: '#1E1E1E',
      secondary: 'rgba(30, 30, 30, 0.6)',
      disabled: 'rgba(30, 30, 30, 0.38)'
    }
  },
  typography: {
    ...defaultTheme.typography,
    h6: {
      ...defaultTheme.typography.h6,
      fontWeight: 'bold'
    }
  }
} as ThemeOptions)

export default theme
