import { TextField, Heading, Button } from 'uniqore-components'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getClientDetails } from 'util/client-events'
import { ClientDetailsRecord } from 'graphql/types'
import { SessionStatus } from 'store/actions/common'
import { useDispatch } from 'react-redux'

const Profile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [clientDetails, setClientDetails] = useState<ClientDetailsRecord>()
  useEffect(() => {
    async function fetchData() {
      const data = await getClientDetails()
      if (data && Array.isArray(data) && data.length > 0) {
        setClientDetails(data[0])
      }
    }
    fetchData().catch(() => dispatch(SessionStatus(false)))
  }, [dispatch])
  return (
    <>
      <Heading
        align="center"
        title="Oma profiili"
        subtitle="Olethan yhteydessä tietojen vaihtamiseen liittyen asiakaspalveluumme huolto.raisio@tehomix.fi"
      />
      <TextField
        fullWidth
        label="Yrityksen nimi"
        prefix={{ text: ' ' }}
        disabled
        value={clientDetails ? clientDetails.fields.Company : ''}
      />
      <TextField
        value={clientDetails ? clientDetails.fields['Client ID'] : ''}
        fullWidth
        label="Tilinumero"
        prefix={{ text: ' ' }}
        disabled
      />
      <TextField
        value={
          clientDetails
            ? clientDetails.fields.Firstname +
              ' ' +
              clientDetails.fields.Lastname
            : ''
        }
        fullWidth
        label="Nimi"
        prefix={{ text: ' ' }}
        disabled
      />
      <TextField
        value={clientDetails ? clientDetails.fields.Email : ''}
        fullWidth
        label="Sähköposti"
        prefix={{ text: ' ' }}
        disabled
      />
      <TextField
        value={clientDetails ? clientDetails.fields.Phone : ''}
        fullWidth
        label="Puhelinnumero"
        prefix={{ text: ' ' }}
        disabled
      />
      <Button
        color="secondary"
        customColor={{
          backgroundColor: '',
          color: ''
        }}
        label="Vaihda salasana"
        metaText=""
        onClick={() => history.push('/reset-password')}
        role=""
        type="submit"
        variant="contained"
        fullWidth
      />
    </>
  )
}

export default Profile
