import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Whitelabel,
  AppBar,
  Heading,
  ListItem,
  Button,
  Dialog
} from 'uniqore-components'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import { DeleteProduct } from 'store/actions/product'

const ProductList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [productId, setProductId] = React.useState<string>('')
  const { ProductList } = useSelector((state: AppState) => state.product)  

  const handleSave = () => {
    history.push('product-message')
  }

  const handleCancel = () => {
    setConfirmOpen(false)
  }

  const handleConfirm = () => {
    setConfirmOpen(false)
    if (productId !== '') {
      dispatch(DeleteProduct(productId))
      history.push('/status/welcome')
    }
  }

  const handleDelete = (id: string) => {
    if (id) {
      setProductId(id)
      if (ProductList.length > 1) {
        dispatch(DeleteProduct(id))
      } else {
        setConfirmOpen(true)
      }
    }
  }

  return (
    <>
      <AppBar
        maxWidth="sm"
        text="Takaisin"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        border
      />
      <Container maxWidth="sm">
        <Heading
          align="center"
          body='Jos laitteesi ei löydy listasta, niin voit lisätä laitteen valitsemalla "Lisää uusi laite".'
          title="Omat laitteet"
        />
        {ProductList
          ? ProductList.map((product: any) => (
              <ListItem
                key={product.id + Math.random()}
                image={product.Image}
                metaIcon="Delete"
                onClick={() => handleDelete(product.id)}
                subtitles={[product.Brand, product.SKU]}
                text={product.Name}
              />
            ))
          : ''}
        <Button
          color="secondary"
          icon="Add"
          label="Lisää uusi laite"
          onClick={() => {
            history.push('/product-category')
          }}
          role="radio"
          type="submit"
          variant="default"
        />
        <div style={{ marginBottom: '100px' }}></div>
        <Button
          style={{ position: 'absolute', bottom: '40px' }}
          color="primary"
          fullWidth
          label="Lisää laiteluettelooni"
          onClick={handleSave}
          type="submit"
          variant="contained"
          maxWidth="sm"
        />
      </Container>
      <Dialog
        actionComponents={[
          <Button
            disablePadding
            label="Peruuta"
            variant="text"
            onClick={() => handleCancel()}
          />,
          <Button
            color="primary"
            disablePadding
            label="OK"
            onClick={() => handleConfirm()}
          />
        ]}
        fullWidth
        maxWidth="sm"
        open={confirmOpen}
        onClose={() => handleCancel}
        subtitle="Poistetaanko tuote ja palataan etusivulle?"
        title="Varmistus"
      />
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ProductList
