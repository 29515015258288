import React from 'react'
import {
  Container,
  AppBar,
  BottomNavigation,
  Whitelabel
} from 'uniqore-components'
import { useHistory } from 'react-router-dom'
import Products from './Products'
import Orders from './Orders'
import Profile from './Profile'

const CustomerTabs = (props: any) => {
  const history = useHistory()

  const tabs: any = {
    products: <Products />,
    orders: <Orders />,
    profile: <Profile />
  }
  const tabContent: Object = tabs[props.tab]

  return (
    <>
      <AppBar
        backgroundColor="transparent"
        color="black"
        logo=""
        text="Etusivulle"
        logoSize={90}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.push('/status/product')
          }
        }}
        position="static"
        showScrollHeight={0}
      />
      <Container style={{ position: 'relative' }}>{tabContent}</Container>
      <div style={{ marginBottom: '90px' }}></div>
      <div
        style={{
          position: 'fixed',
          width: '100%',
          bottom: '0px'
        }}
      >
        <BottomNavigation
          actions={[
            { label: 'Laiteluettelo', value: 'products', icon: 'List' },
            { label: 'Tapahtumat', value: 'orders', icon: 'Refresh' },
            { label: 'Oma profiili', value: 'profile', icon: 'Person' }
          ]}
          value={props.tab}
          onChange={(_event, newValue) => {
            history.push('./' + newValue)
          }}
          maxWidth="sm"
          style={{ border: 'none !important' }}
        />
        <Whitelabel />
      </div>
    </>
  )
}

export default CustomerTabs
