import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TextField, Button, Login } from 'uniqore-components'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import PublicClient from 'api/public'
import { LOGIN_QUERY } from 'graphql/queries'
import { isAutheticated } from 'util/auth'
import { useDispatch } from 'react-redux'
import { SessionStatus } from 'store/actions/common'

const CustomerLogin = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [login, { loading: mutationLoading }] = useMutation(LOGIN_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.loginCustomerPassword.error) {
        localStorage.setItem(
          'token',
          mutationResult.loginCustomerPassword.token
        )
        localStorage.setItem(
          'userId',
          mutationResult.loginCustomerPassword.customerId
        )
        dispatch(SessionStatus(true))
        history.push(window.location.pathname)
      } else {
        setError('Virheelliset tunnukset')
      }
    }
  })

  const submit = (event: React.FormEvent) => {
    event.preventDefault()
    login({
      variables: {
        email: email,
        password: password,
        auth: process.env.REACT_APP_CUSTOMER_AUTH_ID
      }
    })
    return false
  }

  if (isAutheticated()) history.push(window.location.pathname)

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open>
        <DialogContent>
          <form onSubmit={submit} style={{ marginBottom: '20px' }}>
            <Login
              actionComponents={[
                <Button
                  color="primary"
                  fullWidth
                  disabled={mutationLoading}
                  label={
                    mutationLoading ? (
                      <CircularProgress size="24px" color="inherit" />
                    ) : (
                      'KIRJAUDU SISÄÄN'
                    )
                  }
                  type="submit"
                  shadow={true}
                />,
                <Button
                  color="primary"
                  fullWidth
                  label="Salasana unohtunut?"
                  variant="text"
                  shadow={true}
                  onClick={() => {
                    history.push('/reset-password')
                    dispatch(SessionStatus(false))
                  }}
                />
              ]}
              contentComponents={[
                <TextField
                  autoFocus
                  fullWidth
                  name="user"
                  placeholder="Sähköpostiosoite"
                  prefix={{ icon: 'MailOutline' }}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  errorText={error}
                />,
                <TextField
                  fullWidth
                  name="password"
                  placeholder="Salasana"
                  type={passwordVisible ? 'text' : 'password'}
                  prefix={{ icon: 'LockOutlined' }}
                  suffix={{
                    icon: passwordVisible ? 'Visibility' : 'VisibilityOff',
                    onClick: () => setPasswordVisible(!passwordVisible)
                  }}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  errorText={error}
                />
              ]}
              headline="Kirjaudu sisään"
              subtitle="Istuntosi on vanhentunut. Kirjaudu uudelleen."
            />
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CustomerLogin
