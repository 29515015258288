import { ListItem, Heading, Button, Container } from 'uniqore-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'
import { OrderRecord } from 'graphql/types'
import { SessionStatus } from 'store/actions/common'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import PublicClient from 'api/public'
import { GET_ORDERS_QUERY } from 'graphql/queries'

const Orders = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, loading, error } = useQuery(GET_ORDERS_QUERY, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  let orders: OrderRecord[] = []

  if (data) {
    orders = data.serviceEngagements
  }

  if (error) {
    dispatch(SessionStatus(false))
  }

  let feedCards: Object[] = []
  if (orders) {
    orders.forEach(order => {
      let orderId = ''
      let serviceId = null
      order.metadata.forEach((meta: any) => {
        if (meta.key === 'orderId') {
          orderId = 'Tilaus #' + String(meta.value.data.records[0].fields.ID)
        } else if (meta.key === 'Service ID') {
          serviceId = meta.value
        }
      })
      let subTitles = [
        order.stage.name === 'Processing'
          ? 'Tilaus vastaanotettu, toimita huoltoon'
          : order.stage.name === 'Verified'
          ? 'Tilaus vahvistettu, toimita huoltoon'
          : order.stage.name === 'Received'
          ? 'Laite vastaanotettu, odottaa huoltoa'
          : order.stage.name === 'Offered'
          ? 'Huolto aloitettu'
          : order.stage.name === 'Ready'
          ? 'Huolto valmis'
          : order.stage.name === 'Done'
          ? 'Tilaus valmis'
          : order.stage.name === 'Rejected'
          ? 'Tilaus peruttu'
          : 'Tilapäivitys tulossa pian'
      ]
      if(serviceId) subTitles.push('Huolto #' + serviceId)
      feedCards.push(
        <ListItem
          key={Math.random()}
          metaIcon="ArrowForward"
          text={orderId}
          subtitles={subTitles}
          onClick={() => {
            history.push('/customer/order', { order: order })
          }}
        />
      )
    })
  }
  return (
    <>
      <Heading align="center" title="Tapahtumat" />
      {loading ? (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <CircularProgress style={{}} size="24px" color="inherit" />
        </Container>
      ) : (
        <div>{feedCards}</div>
      )}
      <Button
        color="secondary"
        customColor={{
          backgroundColor: '',
          color: ''
        }}
        icon="ArrowForward"
        label="Siirry tilaamaan"
        metaText=""
        onClick={() => {
          history.push('/service/select-product')
        }}
        role=""
        type="submit"
        variant="contained"
        fullWidth
      />
    </>
  )
}

export default Orders
