import { Dispatch } from 'redux'
import { AppActions, CommonType } from 'types/types'

export const CONFIRM_COOKIES = 'CONFIRM_COOKIES'
export const SESSION_STATUS = 'SESSION_STATUS'

export const ConfirmCookies = (status: boolean) => (
  dispatch: Dispatch<AppActions>
): CommonType => {
  return dispatch({
    type: CONFIRM_COOKIES,
    payload: { status }
  })
}

export const SessionStatus = (sessionStatus: boolean) => (
  dispatch: Dispatch<AppActions>
): CommonType => {
  if (!sessionStatus) {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
  }

  return dispatch({
    type: SESSION_STATUS,
    payload: { sessionStatus }
  })
}
