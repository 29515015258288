import PublicClient from 'api/public'
import { GET_ORDERS_QUERY, GET_ORDER_LINES_BY_ORDER_ID_QUERY } from 'graphql/queries'
import { OrderRecord, OrderLine, OrdersDataViewData, OrderLinesDataViewData } from 'graphql/types'

export const getOrderLines = async (airtableId: any): Promise<OrderLine[]> => {
  const { data, error } = await PublicClient.query<OrderLinesDataViewData>({
    query: GET_ORDER_LINES_BY_ORDER_ID_QUERY,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_ORDER_LINES_BY_ORDER_ID_ID,
      parameters: {
        orderId: airtableId
      }
    }
  })
  if (error) return []
  return data?.fetchDataview.data.records || []
}

export const getOrders = async (): Promise<OrderRecord[]> => {
  const { data, error } = await PublicClient.query<OrdersDataViewData>({
    query: GET_ORDERS_QUERY,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  if (error) return []
  let orders: OrderRecord[] = []
  data?.serviceEngagements.forEach(element => {
    let order: any = {}
    element.metadata.forEach(metadata => {      
      switch(metadata.key) {
        case "@airtable":
          order["airtableId"] = metadata.value
          break
        case "order":
          order = {...order, ...metadata.value}
          break
        case "idNumber":
          order["id"] = metadata.value.idNumber
          break
      }
    })
    order["status"] = getOrderStatus(element.stage.name)
    orders.push(order)
  })
  return orders
}

export const getOrderStatus = (status: string) => {
  const mapping: any = {
    "Processing":	  "Tilaus vastaanotettu, toimita huoltoon",
    "Received":     "Laite vastaanotettu, odottaa huoltoa",
    "Offered":      "Laite vastaanotettu, odottaa huoltoa",
    "Accepted":     "Laite vastaanotettu, odottaa huoltoa",
    "In progress":  "Huolto aloitettu",
    "Ready":        "Huolto valmis",
    "Done":         "Tilaus valmis",
    "Rejected":     "Tilaus peruttu"
  }
  return mapping[status]
}