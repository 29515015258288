// @ts-nocheck
import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers/index'

const persistConfig = {
  key: 'commonReducer',
  version: 1,
  storage,
  whitelist: ['common', 'product', 'services']
}

const PersistReducer = persistReducer(persistConfig, rootReducer)

const middlewares = [thunkMiddleware, promise]
const middlewareEnhancer = applyMiddleware(...middlewares)

const enhancers = [middlewareEnhancer]
const composedEnhancers = compose(...enhancers)

export const initStore = createStore(
  PersistReducer,
  composeWithDevTools(composedEnhancers)
)

export const persistor = persistStore(initStore)

const store = {
  initStore,
  persistor
}

export type AppState = ReturnType<typeof rootReducer>
export default store
