import React from 'react'
import Chip from '@material-ui/core/Chip'
import {
  Container,
  Heading,
  Button,
  SmallPrint
} from 'uniqore-components'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import { ServiceRecord } from 'graphql/types'
import { AddServices } from 'store/actions/services'
import TehoAppBar from 'components/common/TehoAppBar'

const SelectRepair = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { SelectedProduct } = useSelector((state: AppState) => state.product)
  const { SelectedProductForService, ServiceList } = useSelector(
    (state: AppState) => state.services
  )
  const { state: availableServices }: any = useLocation()
  const [selectedServices, setSelectedServices] = React.useState<
    ServiceRecord[]
  >()


  const isSelected = (v: ServiceRecord) => {
    return SelectedProductForService && !selectedServices
      ? SelectedProductForService.SelectedServices.some(
          (e: any) => e.id === v.id
        )
      : selectedServices
      ? selectedServices.some(e => e.id === v.id)
      : false
  }

  const unSelect = (v: ServiceRecord) => {
    return SelectedProductForService && !selectedServices
      ? SelectedProductForService.SelectedServices.filter(
          (obj: ServiceRecord) => {
            return obj.id !== v.id
          }
        )
      : selectedServices
      ? selectedServices.filter((obj: ServiceRecord) => {
          return obj.id !== v.id
        })
      : []
  }

  const handleClick = (service: ServiceRecord) => {
    let values = selectedServices ? selectedServices : []
    setSelectedServices(
      isSelected(service)
        ? unSelect(service)
        : () => {
            return [...values, ...[service]]
          }
    )
  }

  const handleSubmit = () => {
    dispatch(AddServices(selectedServices))
    history.push('extra-information')
  }

  const serviceButtons: Object[] = []
  if (availableServices) {
    availableServices.services.forEach((service: ServiceRecord) => {
      serviceButtons.push(
        <Button
          key={service.id}
          checked={isSelected(service)}
          role="checkbox"
          label={service.fields.Issue}
          value={service.id}
          onClick={() => handleClick(service)}
        />
      )
    })
  }

  return (
    <>
      <TehoAppBar
        backgroundColor="transparent"
        color="black"
        logo=""
        logoSize={90}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        text="Palvelu"
        border
        variant={ServiceList ? 'to-home-warning' : 'to-home'}
      />
      <Container>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          {SelectedProduct ? (
            <Chip
              color="primary"
              label={SelectedProduct.fields['Product name']}
            />
          ) : (
            ''
          )}
        </div>
        <Heading
          align="center"
          title="Korjaustarve"
          /* body={
            SelectedService === '1'
              ? 'Voit valita useamman kohdan alta. Kalibrointi tehdään tuntityönä 90,00 € (alv 0%).'
              : 'Voit valita useamman kohdan alta. Korjaustehtävät tehdään tuntityönä 65,00 € (alv 0%) / tunti.'
          }*/
        />
        {serviceButtons ? serviceButtons : ''}
        <SmallPrint
          align="left"
          text="Mikäli laitteessasi on useamman kategorian vikoja, valitse viat samalla kertaa ja kirjoita yksi yhteinen kommentti."
        />
        <SmallPrint
          align="left"
          text="Esiselvityksen perusteella olemme yhteyksissä tarkemman arvion osalta. Minimilaskutus 35,00 € (alv 0%)."
        />
        <div style={{ marginBottom: '100px' }}></div>
        <Button
          disabled={selectedServices ? selectedServices.length === 0 : true}
          style={{ position: 'absolute', bottom: '40px' }}
          color="primary"
          fullWidth
          label="Eteenpäin"
          type="submit"
          variant="contained"
          maxWidth="sm"
          onClick={handleSubmit}
        />
      </Container>
    </>
  )
}

export default SelectRepair
