import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuid } from 'uuid'
import {
  Container,
  ListItem,
  Heading,
  Button,
  Dialog,
  TextField,
  ErrorPage
} from 'uniqore-components'
import { CircularProgress } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import { AddProduct, ClearProduct } from 'store/actions/product'
import styled from 'styled-components/macro'
import { useMutation } from '@apollo/client'
import { getProductsSearch } from 'util/products'
import { ProductRecord } from 'graphql/types'
import { INGEST_CLIENT_PRODUCTS_FORM } from 'graphql/queries'
import PublicClient from 'api/public'
import { SetClientProductsUpdated } from 'store/actions/product'
import { SessionStatus } from 'store/actions/common'
import TehoAppBar from 'components/common/TehoAppBar'

const useStyles = makeStyles(theme => ({
  line: {
    margin: '16px',
    border: '0',
    height: '0',
    opacity: '20%',
    borderTop: `1px solid ${theme.palette.text.primary}`
  }
}))

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProductCard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = React.useState<boolean>(true)
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(true)
  const [serialNumber, setSerialNumber] = React.useState<string>('')
  const { sessionStatus } = useSelector((state: AppState) => state.common)

  const handleSubmit = () => {
    setSerialNumber(value)
    setOpenDialog(false)
  }

  const { SelectedProduct } = useSelector((state: AppState) => state.product)

  const [productsList, setProductsList] = React.useState<ProductRecord[]>()
  useEffect(() => {
    async function fetchData() {
      if (SelectedProduct) {
        const data = await getProductsSearch(SelectedProduct!)
        setProductsList(data)
        setLoading(false)
      }
    }
    fetchData().catch(() => dispatch(SessionStatus(false)))
  }, [SelectedProduct, dispatch])

  let attachments: Object[] = []
  if (productsList !== undefined) {
    productsList.map(product =>
      product.fields.Attachments
        ? product.fields.Attachments.forEach(attachment => {
            attachments.push(
              <ListItem
                actions={[]}
                key={Math.random()}
                metaIcon="CloudDownload"
                hightlightMeta={true}
                onClick={() => {
                  window.open(attachment.url)
                }}
                text={attachment.filename}
              />
            )
          })
        : ''
    )
  }

  const handleSave = () => {
    dispatch(AddProduct({ id: uuid(), ...productsList }))
    saveClientProduct()
  }

  const getProductID = () => {
    let productID: string = ''
    if (productsList !== undefined)
      productsList.map(product => (productID = product.id))
    return productID
  }

  const [saveClientProduct, { loading: mutationLoading }] = useMutation(
    INGEST_CLIENT_PRODUCTS_FORM,
    {
      client: PublicClient,
      variables: {
        id: process.env.REACT_APP_FORM_CREATE_CLIENT_PRODUCT,
        form: {
          product: getProductID(),
          productSerialNumber: serialNumber
        }
      },
      onCompleted(mutationResult) {
        dispatch(ClearProduct())
        dispatch(SetClientProductsUpdated(true))
        history.push('product-message')
      },
      onError(mutationError) {
        if (!localStorage.getItem('token') && sessionStatus) {
          dispatch(SessionStatus(false))
        } else {
          const link = {
            url: window.location.pathname,
            text: 'Yritä uudestaan'
          }
          return (
            <div>
              <ErrorPage
                backgroundColor="#fff"
                color="#000"
                link={link}
                text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
              />
            </div>
          )
        }
      }
    }
  )

  return (
    <>
      <TehoAppBar
        backgroundColor="transparent"
        color="black"
        logoSize={90}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        text="Takaisin"
        variant="to-home"
      />
      <Container maxWidth="sm">
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '30%'
            }}
          >
            <CircularProgress size="30px" color="inherit" />
          </div>
        ) : productsList !== undefined ? (
          productsList.map(product => (
            <div key={product.fields.SKU + Math.random()}>
              <Center>
                <img
                  style={{ width: '200px' }}
                  src={product.fields['Main image'][0].thumbnails.large.url}
                  alt={product.fields.Name}
                />
              </Center>
              <Heading
                align="left"
                title={product.fields.Name}
                body={
                  product.fields.Category +
                  ' / ' +
                  product.fields['Sub-category']
                }
              />
              <Heading
                align="left"
                body={'' + product.fields['Description (short)']}
              />
              <Heading
                align="left"
                subtitle="Liitteet"
                style={{ fontWeight: 'bold' }}
              />
              {attachments}
              <hr className={classes.line} />
              <ListItem
                maxWidth="sm"
                text="Tuotteen tiedot"
                metaIcon={open ? 'ExpandLess' : 'ExpandMore'}
                onClick={() => setOpen(!open)}
              />
              <Collapse in={open}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Heading subtitle="Sarjanumero" align="left" />
                  <Button
                    label={serialNumber || 'Lisää sarjanumero'}
                    color="primary"
                    onClick={() => setOpenDialog(!openDialog)}
                  />
                </div>
              </Collapse>
              <div style={{ paddingBottom: '30px' }}></div>
            </div>
          ))
        ) : (
          ''
        )}
        <Dialog
          title="Sarjanumero"
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          actionComponents={[
            <Button
              label="Peruuta"
              variant="text"
              disablePadding
              onClick={() => setOpenDialog(false)}
            />,
            <Button
              label="Lisää"
              color="primary"
              disablePadding
              onClick={handleSubmit}
            />
          ]}
        >
          <TextField
            disableGutters
            autoFocus
            fullWidth
            label="Lisää sarjanumero"
            onChange={event => {
              setValue(event.target.value)
            }}
          />
        </Dialog>
        <div style={{ marginBottom: '100px' }}></div>
        <Button
          disabled={mutationLoading}
          style={{ position: 'absolute', bottom: '40px' }}
          color="primary"
          fullWidth
          label={
            mutationLoading ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              'Lisää laiteluettelooni'
            )
          }
          onClick={handleSave}
          type="submit"
          variant="contained"
          maxWidth="sm"
        />
      </Container>
    </>
  )
}

export default ProductCard
