// @ts-nocheck
import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat } from 'apollo-link'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL
})

const analyticsMiddleware = new ApolloLink((operation, forward) => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: operation.operationName,
    payload: operation.variables
  })
  return forward(operation)
})

const Client = new ApolloClient({
  link: concat(analyticsMiddleware, httpLink),
  cache: new InMemoryCache()
})

export default Client
