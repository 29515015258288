import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Whitelabel,
  Button,
  Heading
} from 'uniqore-components'
import SearchDialogComponent from './SearchDialogComponent'
import { useDispatch, useSelector } from 'react-redux'
import { AddSubCategory } from 'store/actions/product'
import { AppState } from 'types/types'
import TehoAppBar from 'components/common/TehoAppBar'

const SubCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<string>()

  const handleClick = (v: string) => {
    setValue(v)
    dispatch(AddSubCategory(v))
    history.push('/product-catalog')
  }

  const { SelectedCategory } = useSelector((state: AppState) => state.product)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <TehoAppBar
        maxWidth="sm"
        text="Valitse tuotekategoria"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        border
        actions={[
          {
            icon: 'Search',
            onClick: toggleOpen
          }
        ]}
        variant="to-home"
      />
      <Container maxWidth="sm">
        <Heading align="center" title={SelectedCategory} />
        {SelectedCategory === 'Rakennuslaserit' ? (
          <div>
            <Button
              checked={value === 'Linjalaserit'}
              role="radio"
              label="Linjalaserit"
              value="Linjalaserit"
              onClick={() => handleClick('Linjalaserit')}
            />
            <Button
              checked={value === 'Tasolaserit'}
              role="radio"
              label="Tasolaserit"
              value="Tasolaserit"
              onClick={() => handleClick('Tasolaserit')}
            />
          </div>
        ) : (
          <div>
            <Button
              checked={value === 'Maaliruiskut'}
              role="radio"
              label="Maaliruiskut"
              value="Maaliruiskut"
              onClick={() => handleClick('Maaliruiskut')}
            />
            <Button
              checked={value === 'Tasoiteruiskut'}
              role="radio"
              label="Tasoiteruiskut"
              value="Tasoiteruiskut"
              onClick={() => handleClick('Tasoiteruiskut')}
            />
          </div>
        )}
        <SearchDialogComponent open={open} onClose={toggleOpen} />
      </Container>
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default SubCategory
