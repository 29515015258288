import React, { useState } from 'react'
import xss from 'xss'
import { useTheme } from '@material-ui/core'
import { Dialog, Button, TextField, Heading, Select } from 'uniqore-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Snackbar, IconButton, Grid, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { useHistory } from 'react-router-dom'
import SpeedDial from './speeddial/SpeedDial'
import { useMutation } from '@apollo/client'
import PublicClient from 'api/public'
import { INGEST_FORM } from 'graphql/queries'

const StatusSpeeddial = () => {
  const history = useHistory()
  const theme = useTheme()
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [openContactDetails, setOpenContactDetails] = React.useState<boolean>(
    false
  )
  const [value, setValue] = React.useState<string>()
  const [errorText, setErrorText] = useState<string>('')
  const [typeValue, setTypeValue] = useState<string>('yhteydenotto')
  const [successMessage, showSuccessMessage] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const [sendNoteAction, { loading: mutationLoading }] = useMutation(
    INGEST_FORM,
    {
      client: PublicClient,
      onCompleted(mutationResult) {
        if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
          setErrorText(
            'Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.'
          )
        } else if (mutationResult.ingestForm.ok) {
          setValue('')
          setOpenDialog(false)
          setErrorText('')
          showSuccessMessage(true)
        }
      }
    }
  )

  const handleSubmit = () => {
    if (value) {
      setOpenDialog(false)
      sendNoteAction({
        variables: {
          id: process.env.REACT_APP_SEND_NOTE_INGEST_FORM_ID,
          form: {
            type: typeValue,
            note: value,
            firstName: localStorage.getItem('firstName'),
            lastName: localStorage.getItem('lastName'),
            company: localStorage.getItem('companyName'),
            phone: localStorage.getItem('phone'),
            email: localStorage.getItem('email')
          }
        }
      })
    }
  }

  const actions = [
    {
      icon: 'Build',
      label: 'Laitetiedot ja profiili',
      onClick: () => {
        history.push('/customer/products')
      }
    },
    {
      icon: 'AddCircleOutline',
      label: 'Tilaa huolto',
      onClick: () => history.push('/service/select-product')
    },
    {
      icon: 'Send',
      label: 'Ota yhteyttä',
      onClick: () => {
        setOpenDialog(true)
      }
    },
    {
      icon: 'ContactPhone',
      label: 'Yhteystiedot',
      onClick: () => {
        setOpenContactDetails(true)
      }
    }
  ]
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setShowSnackbar(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert severity="error">
          Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successMessage}
        autoHideDuration={6000}
        onClose={() => showSuccessMessage(false)}
        message="Kiitos viestistäsi! Palaamme asiaan mahdollisimman pian."
        color="primary"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => showSuccessMessage(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
      <SpeedDial
        style={{
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2)
        }}
        backgroundColor={theme.palette.primary.main}
        actions={actions}
      />
      <Dialog
        title={
          typeValue === 'palautteenanto' ? 'Anna palautetta' : 'Ota yhteyttä'
        }
        subtitle={
          typeValue === 'palautteenanto'
            ? 'Antamanne palaute auttaa parantamaan palvelujamme.'
            : 'Ota rohkeasti yhteyttä.'
        }
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        actionComponents={[
          <Button
            label="Peruuta"
            variant="text"
            disablePadding
            onClick={() => setOpenDialog(false)}
          />,
          <Button
            label={
              mutationLoading ? (
                <CircularProgress size="24px" color="inherit" />
              ) : (
                'Lähetä'
              )
            }
            color="primary"
            disablePadding
            onClick={handleSubmit}
          />
        ]}
      >
        <Select
          value={typeValue}
          options={[
            { label: 'Yhteydenotto', value: 'yhteydenotto' },
            { label: 'Palautteenanto', value: 'palautteenanto' }
          ]}
          onChange={e => setTypeValue(e.target.value)}
        />
        <TextField
          fullWidth
          label="Lisää kommentti"
          rows={5}
          value={value}
          onChange={event => setValue(event.target.value)}
          errorText={errorText}
        />
      </Dialog>
      <Dialog
        title="Yhteystiedot"
        open={openContactDetails}
        onClose={() => setOpenContactDetails(false)}
        actionComponents={[
          <Button
            label="OK"
            color="primary"
            disablePadding
            onClick={() => setOpenContactDetails(false)}
          />
        ]}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignContent="space-between"
        >
          <Grid style={{ marginRight: '10px' }}>
            <Heading align="left" title="Raisio" />
            <Typography
              style={{ marginLeft: '15px' }}
              variant="subtitle1"
              dangerouslySetInnerHTML={{
                __html: xss(
                  'Avoinna ma-pe klo 8:00-16:00<br />Sammalkallionkatu 18 G, H,<br />21200 RAISIO<br />029 123 1111<br /><a href="mailto:tehomix@tehomix.fi">tehomix@tehomix.fi</a>'
                )
              }}
            />
          </Grid>
          <Grid>
            <Heading align="left" title="Vantaa" />
            <Typography
              style={{ marginLeft: '15px' }}
              variant="subtitle1"
              dangerouslySetInnerHTML={{
                __html: xss(
                  'Avoinna ma-pe klo 7:00-16:00<br />Niittytie 25 B 8,<br />01300 VANTAA<br />029 123 1113<br /><a href="mailto:vantaa@tehomix.fi">vantaa@tehomix.fi</a>'
                )
              }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default StatusSpeeddial
