import { AppActions, ServicesActionType } from 'types/types'
import { Dispatch } from 'redux'
import { DeliveryDetailsType } from 'components/service/ExtraInformation'

export const ADD_SERVICES = 'ADD_SERVICES'
export const ADD_ISSUE = 'ADD_ISSUE'
export const ADD_COMMENT = 'ADD_COMMENT'
export const CHOOSE_SERVICE = 'CHOOSE_SERVICE'
export const ADD_SERVICE_LIST = 'ADD_SERVICE_LIST'
export const EDIT_SERVICE_LIST = 'EDIT_SERVICE_LIST'
export const REMOVE_SERVICE_LIST = 'REMOVE_SERVICE_LIST'
export const CLEAR_SERVICE_LIST = 'CLEAR_SERVICE_LIST'
export const CLEAR_SELECTED_PRODUCT_FOR_SERVICE =
  'CLEAR_SELECTED_PRODUCT_FOR_SERVICE'
export const ADD_PRODUCT_FOR_SERVICE = 'ADD_PRODUCT_FOR_SERVICE'
export const ADD_DELIVERY_METHODS = 'ADD_DELIVERY_METHODS'
export const ADD_DELIVERY_DETAILS = 'ADD_DELIVERY_DETAILS'

export const AddServices = (SelectedServices: any) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: ADD_SERVICES,
    payload: { SelectedServices }
  })
}

export const AddDeliveryMethods = (SelectedMethods: any) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: ADD_DELIVERY_METHODS,
    payload: { SelectedMethods }
  })
}

export const AddComment = (Comments: any) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: ADD_COMMENT,
    payload: { Comments }
  })
}

export const AddDeliveryDetails = (deliveryDetails: DeliveryDetailsType) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: ADD_DELIVERY_DETAILS,
    payload: { deliveryDetails }
  })
}

export const AddServiceList = (ServiceList: any) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: ADD_SERVICE_LIST,
    payload: { ServiceList }
  })
}

export const EditServiceList = (productID: string, details: any) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: EDIT_SERVICE_LIST,
    payload: { productID, details }
  })
}

export const RemoveServiceList = (productID: string) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: REMOVE_SERVICE_LIST,
    payload: { productID }
  })
}

export const ClearServiceList = () => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: CLEAR_SERVICE_LIST
  })
}

export const ClearSelectedProductForService = () => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: CLEAR_SELECTED_PRODUCT_FOR_SERVICE
  })
}

export const AddProductForService = (productID: string) => (
  dispatch: Dispatch<AppActions>
): ServicesActionType => {
  return dispatch({
    type: ADD_PRODUCT_FOR_SERVICE,
    payload: { productID }
  })
}
