import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Whitelabel,
  Button,
  Heading
} from 'uniqore-components'
import SearchDialogComponent from './SearchDialogComponent'
import { useDispatch } from 'react-redux'
import { AddCategory } from 'store/actions/product'
import TehoAppBar from 'components/common/TehoAppBar'

const ProductCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<string>()

  const handleClick = (v: string) => {
    setValue(v)
    dispatch(AddCategory(v))
    history.push('/product-sub-category')
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <TehoAppBar
        maxWidth="sm"
        text="Takaisin"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        border
        actions={[
          {
            icon: 'Search',
            onClick: toggleOpen
          }
        ]}
        variant="to-home"
      />
      <Container maxWidth="sm">
        <Heading align="center" title="Valitse tuotekategoria" />
        <Button
          checked={value === 'Rakennuslaserit'}
          role="radio"
          label="Rakennuslaserit"
          value="Rakennuslaserit"
          onClick={() => handleClick('Rakennuslaserit')}
        />
        <Button
          checked={value === 'Maalauslaitteet'}
          role="radio"
          label="Maalauslaitteet"
          value="Maalauslaitteet"
          onClick={() => handleClick('Maalauslaitteet')}
        />
        <SearchDialogComponent open={open} onClose={toggleOpen} />
      </Container>
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ProductCategory
