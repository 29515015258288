import React, { useCallback, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Container,
  TextField,
  Button,
  Login,
  Spinner
} from 'uniqore-components'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import PublicClient from 'api/public'
import { LOGIN_QUERY } from 'graphql/queries'
import { isAutheticated } from 'util/auth'
import { getClientProducts } from 'util/products'
import { useDispatch } from 'react-redux'
import { SessionStatus } from 'store/actions/common'

const CustomerLogin = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const checkProduct = useCallback(async () => {
    setLoading(true)
    try {
      const data = await getClientProducts()
      if (data && data.length === 0) {
        history.push('/status/welcome')
      } else {
        history.push('/status/product')
      }
    } catch (error) {
      setLoading(false)
    }
  }, [history])

  const [login, { loading: mutationLoading }] = useMutation(LOGIN_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.loginCustomerPassword.error) {
        localStorage.setItem(
          'token',
          mutationResult.loginCustomerPassword.token
        )
        localStorage.setItem(
          'userId',
          mutationResult.loginCustomerPassword.customerId
        )
        dispatch(SessionStatus(true))
        checkProduct()
        setLoading(false)
      } else {
        setError('Virheelliset tunnukset')
      }
    }
  })

  const submit = (event: React.FormEvent) => {
    event.preventDefault()
    login({
      variables: {
        email: email,
        password: password,
        auth: process.env.REACT_APP_CUSTOMER_AUTH_ID
      }
    })
    return false
  }

  React.useEffect(() => {
    if (isAutheticated()) {
      checkProduct()
    }
  }, [checkProduct])

  return (
    <>
      <Container>
        {loading ? (
          <Spinner
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '50px'
            }}
            variant="defaultLight"
          />
        ) : (
          <form onSubmit={submit} style={{ marginTop: '35%' }}>
            <Login
              actionComponents={[
                <Button
                  color="primary"
                  fullWidth
                  disabled={mutationLoading}
                  label={
                    mutationLoading ? (
                      <CircularProgress size="24px" color="inherit" />
                    ) : (
                      'KIRJAUDU SISÄÄN'
                    )
                  }
                  type="submit"
                  shadow={true}
                />,
                <Button
                  color="primary"
                  fullWidth
                  label="Salasana unohtunut?"
                  variant="text"
                  shadow={true}
                  onClick={() => history.push('/reset-password')}
                />
              ]}
              contentComponents={[
                <TextField
                  autoFocus
                  fullWidth
                  name="user"
                  placeholder="Sähköpostiosoite"
                  prefix={{ icon: 'MailOutline' }}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  errorText={error}
                />,
                <TextField
                  fullWidth
                  name="password"
                  placeholder="Salasana"
                  type={passwordVisible ? 'text' : 'password'}
                  prefix={{ icon: 'LockOutlined' }}
                  suffix={{
                    icon: passwordVisible ? 'Visibility' : 'VisibilityOff',
                    onClick: () => setPasswordVisible(!passwordVisible)
                  }}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  errorText={error}
                />
              ]}
              headline="Kirjaudu sisään"
              subtitle=""
            />
          </form>
        )}
      </Container>
    </>
  )
}

export default CustomerLogin
