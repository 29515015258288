import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Whitelabel,
  AppBar,
  Heading,
  ListItem,
  Button,
  Container,
  Dialog,
  ErrorPage
} from 'uniqore-components'
import CustomCheckbox from 'components/common/CustomCheckbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'types/types'
import {
  AddProductForService,
  RemoveServiceList,
  ClearSelectedProductForService
} from 'store/actions/services'
import { useMutation } from '@apollo/client'
import PublicClient from 'api/public'
import { INGEST_FORM } from 'graphql/queries'
import { ClearProduct } from 'store/actions/product'
import { SessionStatus } from 'store/actions/common'

const ServiceSummary = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { ServiceList, SelectedMethods, DeliveryDetails } = useSelector(
    (state: AppState) => state.services
  )

  const deliveryName =
    DeliveryDetails?.firstName && DeliveryDetails.lastName
      ? DeliveryDetails?.firstName + ' ' + DeliveryDetails.lastName
      : ''

  const deliveryAddress = DeliveryDetails
    ? DeliveryDetails?.zip +
      ', ' +
      DeliveryDetails?.address +
      ', ' +
      DeliveryDetails?.city
    : ''

  const deliveryPhoneNumber = DeliveryDetails?.phoneNumber ?? ''
  const [open, setOpen] = React.useState(true)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [orderId, setOrderId] = React.useState<string>('')
  const [agreement, setAgreement] = React.useState(false)

  const getForm = () => {
    let orderLines: any = []
    let products: any = []
    if (ServiceList) {
      ServiceList.forEach((record: any) => {
        if (record.SelectedServices) {
          if (!products.includes(record.Product.fields['Product name'][0])) {
            products.push(record.Product.fields['Product name'][0])
          }
          record.SelectedServices.forEach((service: any) => {
            orderLines.push({
              service: service.id,
              clientProduct: record.Product.id,
              deliveryMethod: SelectedMethods[0].id,
              deliveryMethodText: SelectedMethods[0].option,
              deliveryDetails: DeliveryDetails,
              comment: record.Comment ? record.Comment : '-',
              category: service.fields.Category ? service.fields.Category : '',
              productName: record.Product.fields['Product name'][0]
                ? record.Product.fields['Product name'][0]
                : '',
              address: record.Product.fields.Address[0]
                ? record.Product.fields.Address[0]
                : '',
              issue: service.fields.Issue ? service.fields.Issue : '',
              SKU: record.Product.fields['Product SKU'][0]
                ? record.Product.fields['Product SKU'][0]
                : '-',
              serialNumber: record.Product.fields['Product serial number']
                ? record.Product.fields['Product serial number']
                : '-'
            })
          })
        }
      })
    }
    return {
      orderLines,
      products,
      currentUser: {
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        phone: localStorage.getItem('phone'),
        city: localStorage.getItem('city'),
        email: localStorage.getItem('email'),
        address: localStorage.getItem('address'),
        zip: localStorage.getItem('zip'),
        company: localStorage.getItem('companyName')
      }
    }
  }

  const [saveOrder, { loading: mutationLoading }] = useMutation(INGEST_FORM, {
    client: PublicClient,
    variables: {
      id: process.env.REACT_APP_FORM_CREATE_ORDER_ID,
      form: getForm()
    },
    onCompleted(mutationResult) {
      dispatch(ClearSelectedProductForService())
      dispatch(ClearProduct())
      history.push('order-confirmation')
    },
    onError(error) {
      if (error) {
        if (!localStorage.getItem('token') && SessionStatus) {
          dispatch(SessionStatus(false))
        } else {
          const link = {
            url: window.location.pathname,
            text: 'Yritä uudestaan'
          }
          return (
            <div>
              <ErrorPage
                backgroundColor="#fff"
                color="#000"
                link={link}
                text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
              />
            </div>
          )
        }
      }
    }
  })

  const handleEdit = (id: string, routeName: string) => {
    dispatch(AddProductForService(id))
    history.push(routeName)
  }

  const handleCancel = () => {
    setConfirmOpen(false)
  }

  const handleConfirm = () => {
    setConfirmOpen(false)
    if (orderId !== '') {
      dispatch(RemoveServiceList(orderId))
      dispatch(ClearSelectedProductForService())
      dispatch(ClearProduct())
      history.push('/status/product')
    }
  }

  const handleDelete = (id: string) => {
    setOrderId(id)
    if (ServiceList.length > 1) {
      dispatch(RemoveServiceList(id))
    } else {
      setConfirmOpen(true)
    }
  }

  const getServiceTitle = (services: any) => {
    if (services && services.length > 0) {
      return services[0].fields['Category']
    }
  }

  const getIssuesTitle = (services: any) => {
    if (services && services.length > 0) {
      let issues: any = []
      services.forEach((service: any) => {
        issues.push(service.fields['Issue'])
      })
      return issues.join(', ')
    }
  }

  return (
    <>
      <AppBar
        maxWidth="sm"
        text="Huoltotiedot"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => {
            history.goBack()
          }
        }}
        position="static"
        showScrollHeight={0}
        border
      />
      <Heading maxWidth="sm" align="center" title="Tilauksen yhteenveto" />
      {ServiceList && ServiceList !== '' ? (
        <div>
          <ListItem
            maxWidth="sm"
            text="Laite ja tilattu palvelu"
            subtitles={[
              `${ServiceList.length} laitetta, ${ServiceList.length} palvelua`
            ]}
            metaIcon={open ? 'ExpandLess' : 'ExpandMore'}
            onClick={() => setOpen(!open)}
          />
          <Container>
            <Collapse in={open}>
              {ServiceList.map((record: any) => (
                <Paper
                  style={{
                    marginBottom: '5px',
                    marginLeft: '15px',
                    marginRight: '15px'
                  }}
                  key={Math.random()}
                >
                  <ListItem
                    maxWidth="sm"
                    key={Math.random()}
                    image={
                      record.Product.fields['Product main image'][0].thumbnails
                        .small.url
                    }
                    metaIcon="Edit"
                    subtitles={[
                      `${record.Product.fields['Product name']}`,
                      `${record.Product.fields['Product category']} / ${record.Product.fields['Product subcategory']}`
                    ]}
                    text="Laite"
                    actions={[
                      {
                        label: 'Muokkaa',
                        icon: 'Edit',
                        onClick: () =>
                          handleEdit(record.id, '/service/select-product')
                      },
                      {
                        label: 'Poista',
                        icon: 'RemoveCircle',
                        onClick: () => handleDelete(record.id)
                      }
                    ]}
                  />
                  <ListItem
                    maxWidth="sm"
                    key={Math.random()}
                    subtitles={[
                      getServiceTitle(record.SelectedServices),
                      getIssuesTitle(record.SelectedServices)
                    ]}
                    text="Palvelu"
                  />
                  <ListItem
                    maxWidth="sm"
                    key={Math.random()}
                    subtitles={[SelectedMethods[0].option]}
                    text="Toimitustapa"
                  />
                  {DeliveryDetails?.address && (
                    <ListItem
                      maxWidth="sm"
                      key={Math.random()}
                      subtitles={[
                        deliveryName,
                        deliveryAddress,
                        deliveryPhoneNumber
                      ]}
                      text="Noudon yhteystiedot"
                    />
                  )}
                  {record.Comment !== undefined && record.Comment !== '' ? (
                    <ListItem
                      maxWidth="sm"
                      key={Math.random()}
                      subtitles={[`${record.Comment ? record.Comment : ''}`]}
                      text="Ongelman kuvaus"
                    />
                  ) : (
                    ''
                  )}
                  <ListItem
                    maxWidth="sm"
                    key={Math.random()}
                    subtitles={[record.Product.fields.Address[0]]}
                    text="Toimitus"
                  />
                </Paper>
              ))}
            </Collapse>
          </Container>
          <ListItem
            maxWidth="sm"
            subtitles={[
              'Mikäli laitteesi hyväksytään takuukäsittelyn alaiseksi, tekemämme toimenpiteet ovat veloituksettomia.'
            ]}
            text="Hinnoittelu"
          />
          <ListItem
            maxWidth="sm"
            subtitles={[
              'Tuntityö 65,00 € (alv 0%) / tunti. Selvitystyön jälkeen annamme tarkemman hinnan korjaustyölle. Minimilaskutus 35,00 € (alv 0%).'
            ]}
          />
          <Container maxWidth="sm">
            <CustomCheckbox
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
              label={`Hyväksyn <a href="https://tehomix.fi/wp-content/uploads/2022/02/Yleiset_tilaus-ja_toimitusehdot_2022_Tehomix.pdf" target="_blank">tilaus - ja käyttöehdot</a>`}
            />
          </Container>
        </div>
      ) : (
        ''
      )}
      <div style={{ marginBottom: '100px' }}></div>
      <Container>
        <Button
          style={{
            position: 'absolute',
            bottom: '40px',
            textAlign: 'center'
          }}
          disabled={!agreement || mutationLoading}
          color="primary"
          fullWidth
          label={
            mutationLoading ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              'Tilaa'
            )
          }
          onClick={() => {
            saveOrder()
          }}
          type="submit"
          variant="contained"
          maxWidth="sm"
        />
      </Container>
      <Dialog
        actionComponents={[
          <Button
            disablePadding
            label="Peruuta"
            variant="text"
            onClick={() => handleCancel()}
          />,
          <Button
            color="primary"
            disablePadding
            label="Poista"
            onClick={() => handleConfirm()}
          />
        ]}
        fullWidth
        maxWidth="sm"
        open={confirmOpen}
        onClose={() => handleCancel}
        subtitle="Poistetaanko tilaus ja palataan etusivulle?"
        title="Varmistus"
      />
      <Whitelabel
        style={{ position: 'absolute', bottom: '0px', width: '100%' }}
      />
    </>
  )
}

export default ServiceSummary
