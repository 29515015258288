// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { initStore, persistor } from 'store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from 'theme/theme'
import { ApolloProvider } from '@apollo/client'
import Client from 'api/index'
import './App.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

//export const store = initStore()

ReactDOM.render(
  <Provider store={initStore}>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={Client}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </ApolloProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
