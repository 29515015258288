import { gql } from '@apollo/client'

export const GET_FIRSTNAME = gql`
  query thisCustomer {
    thisCustomer {
      firstName
      email
      lastName
      phone
      companyName
      address
      zip
      city
    }
  }
`

export const GET_PRODUCTS_QUERY = gql`
  query GetProducts($id: ID!) {
    fetchDataview(id: $id)
  }
`

export const GET_PRODUCTS_SEARCH_QUERY = gql`
  query GetProductsSearch($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

export const GET_PRODUCTS_SEARCH_CATEGORY_QUERY = gql`
  query GetProductsSearchByCategory($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

export const INGEST_CLIENT_PRODUCTS_FORM = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

export const GET_SERVICES_BY_PRODUCT_QUERY = gql`
  query GetServicesByProduct($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

export const GET_DELIVERY_METHODS_BY_PRODUCT_QUERY = gql`
  query GetServicesByProduct($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

export const GET_CLIENT_PRODUCTS_QUERY = gql`
  query GetClientProducts($id: ID!) {
    fetchDataview(id: $id)
  }
`

export const GET_CLIENT_EVENTS_QUERY = gql`
  query GetClientEvents($id: ID!) {
    fetchDataview(id: $id)
  }
`

export const GET_CLIENT_DETAILS_QUERY = gql`
  query GetClientDetails($id: ID!) {
    fetchDataview(id: $id)
  }
`

export const GET_ORDER_LINES_BY_ORDER_ID_QUERY = gql`
  query GetOrderLinesByOrderId($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

export const GET_ORDERS_QUERY = gql`
  query GetOrders {
    serviceEngagements {
      id
      idNumber
      stage { name }
      metadata {
        key
        value
      }
    }
  }
`

export const INGEST_FORM = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

export const LOGIN_QUERY = gql`
  mutation login($auth: ID!, $email: String!, $password: String!) {
    loginCustomerPassword(auth: $auth, email: $email, password: $password) {
      customerId
      token
      error
    }
  }
`