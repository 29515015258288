// @ts-nocheck
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client'

import { authLink } from './authLink'
import { errorLink } from './errorLink'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APIURL
})

const PublicClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache()
})

export default PublicClient
