import { CommonType } from 'types/types'
import { CONFIRM_COOKIES, SESSION_STATUS } from 'store/actions/common'

const initialState = {
  status: false,
  sessionStatus: false
}

const common = (state = initialState, action: CommonType) => {
  switch (action.type) {
    case CONFIRM_COOKIES:
      return { ...state, status: action.payload.status }
    case SESSION_STATUS:
      return { ...state, sessionStatus: action.payload.sessionStatus }
    default:
      return state
  }
}
export default common
