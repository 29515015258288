export const isAutheticated = () => {
  return getCustomerId() && getToken()
}

export const getCustomerId = () => {
  return localStorage.getItem('userId')
}

export const getToken = () => {
  return localStorage.getItem('token')
}