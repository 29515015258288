import React from 'react'
import { useHistory } from 'react-router-dom'
import { SearchDialog, ListItem } from 'uniqore-components'
import { useDispatch } from 'react-redux'
import { SelectProduct } from 'store/actions/product'
import { getProductsSearch } from 'util/products'
import { ProductRecord } from 'graphql/types'

const SearchDialogComponent = (props: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [searchResult, setSearchResult] = React.useState<ProductRecord[]>()

  const handleChange = (event: any) => {
    const value = event.target.value.toLowerCase()
    async function fetchData() {
      const data = await getProductsSearch(value)
      setSearchResult(data)
    }
    fetchData()
    setSearchValue(event.target.value)
  }

  const handleProductClick = (SKU: string) => {
    dispatch(SelectProduct(SKU))
    history.push('product-details')
  }

  return (
    <>
      <SearchDialog
        open={props.open}
        searchValue={searchValue}
        onClose={props.onClose}
        onChange={handleChange}
        inputPlaceholder="Etsi..."
        resultsHeading="Hakutulokset"
        resultsPlaceholder={`Hae esim. "Cubus", "Ruiskut" tai "110 S"`}
        searchResults={searchResult?.map(product => (
          <ListItem
            key={product.fields.SKU + Math.random()}
            image={product.fields['Main image'][0].thumbnails.small.url}
            metaIcon="ArrowForward"
            onClick={() => handleProductClick(product.fields.SKU)}
            subtitles={[product.fields.Brand, product.fields.SKU]}
            text={product.fields.Name}
          />
        ))}
      />
    </>
  )
}

export default SearchDialogComponent
