import produce from 'immer'
import { ServiceListState, ServicesActionType } from 'types/types'
import {
  ADD_SERVICES,
  ADD_COMMENT,
  ADD_SERVICE_LIST,
  EDIT_SERVICE_LIST,
  REMOVE_SERVICE_LIST,
  CLEAR_SERVICE_LIST,
  CLEAR_SELECTED_PRODUCT_FOR_SERVICE,
  ADD_PRODUCT_FOR_SERVICE,
  ADD_DELIVERY_METHODS,
  ADD_DELIVERY_DETAILS
} from 'store/actions/services'

const initialState: ServiceListState = {
  ServiceList: '',
  SelectedProductForService: '',
  SelectedServices: '',
  Comments: '',
  SelectedMethods: '',
  DeliveryDetails: {
    firstName: '',
    lastName: '',
    address: '',
    zip: '',
    city: '',
    phoneNumber: ''
  }
}

const services = (state = initialState, action: ServicesActionType) => {
  switch (action.type) {
    case ADD_SERVICES:
      return produce(state, draft => {
        draft.SelectedServices = action.payload.SelectedServices
      })
    case ADD_DELIVERY_METHODS:
      return produce(state, draft => {
        draft.SelectedMethods = action.payload.SelectedMethods
      })
    case ADD_COMMENT:
      return produce(state, draft => {
        draft.Comments = action.payload.Comments
      })
    case ADD_DELIVERY_DETAILS:
      return produce(state, draft => {
        draft.DeliveryDetails = action.payload.deliveryDetails
      })
    case ADD_SERVICE_LIST:
      return {
        ...state,
        ServiceList: [action.payload.ServiceList, ...state.ServiceList]
      }
    case EDIT_SERVICE_LIST:
      const payload = action.payload
      return produce(state, draft => {
        draft.ServiceList = state.ServiceList.map((p: any) =>
          p.id === payload.productID ? payload.details : p
        )
      })
    case REMOVE_SERVICE_LIST:
      return produce(state, draft => {
        draft.ServiceList = state.ServiceList.filter(
          (record: any) => record.id !== action.payload.productID
        )
      })
    case CLEAR_SERVICE_LIST:
      return initialState
    case CLEAR_SELECTED_PRODUCT_FOR_SERVICE:
      return produce(state, draft => {
        draft.SelectedProductForService = ''
      })
    case ADD_PRODUCT_FOR_SERVICE:
      return produce(state, draft => {
        draft.SelectedProductForService = state.ServiceList.find(
          (record: any) => record.id === action.payload.productID
        )
      })
    default:
      return state
  }
}

export default services
